import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fuelType'
})
export class FuelTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    if (!value || value.trim() === '') {
      return '';
    }

    const gasolineKeywords = ['gasoline', '92', '95', '98'];
    const ethanolKeywords = ['ethanol'];
    const dieselKeywords = ['diesel', 'diesel fuel'];
    const gasKeywords = ['gas', 'gas (gasoline)'];

    const items = value.split(',').map(item => item.trim().toLowerCase());
    const numericValues = items
      .filter(item => !isNaN(Number(item)))
      .map(Number)
      .sort((a, b) => a - b);

    const fuelTypes = new Set<string>();

    if (items.some(item => gasolineKeywords.includes(item))) {
      const label = this.translate.instant('common.gasoline');
      const numbers = numericValues.length > 0 ? numericValues.join('/') : null;
      fuelTypes.add(numbers ? `${label} ${numbers}` : label);
    }

    if (items.some(item => ethanolKeywords.includes(item))) {
      fuelTypes.add(this.translate.instant('common.ethanol'));
    }

    if (items.some(item => dieselKeywords.includes(item))) {
      fuelTypes.add(this.translate.instant('common.diesel'));
    }

    if (items.some(item => gasKeywords.includes(item))) {
      fuelTypes.add(this.translate.instant('common.gas'));
    }

    // Jeśli nie ma specyficznych słów kluczowych, ale są liczby, traktuj je jako benzynę
    if (fuelTypes.size === 0 && numericValues.length > 0) {
      const label = this.translate.instant('common.gasoline');
      fuelTypes.add(`${label} ${numericValues.join('/')}`);
    }

    return Array.from(fuelTypes).join(', ');
  }
}
