import {ModuleWithProviders, NgModule} from '@angular/core';
import {SanitizeHtmlPipe} from './sanitize-html-pipe';
import {DateAgoPipe} from './date-ago.pipe';
import {TruncateOnWordPipe} from './truncate-on-word.pipe';
import {MediaObjectPipe} from './media-object.pipe';
import {OwnerRolePipe} from './owner-role.pipe';
import {PercentageCountPipe} from './percentage-count.pipe';
import {UcFirstPipe} from './uc-first.pipe';
import {DurationFormatPipe} from './duration-format.pipe';
import {ParticipationPipe} from './participation.pipe';
import {SanitizeUrlPipe} from './sanitize-url-pipe';
import {ReactionStateClassMapPipe} from './reaction-state-class-map.pipe';
import { TranslateSelectorPipe } from './translate-selector.pipe';
import {ProjectUserCountPipe} from './project-user-count.pipe';
import {FilterCollectionByTypePipe} from './filter-collection-by-type.pipe';
import { RemoveHtmlTagsPipe } from './remove-html-tags.pipe';
import {FuelTypePipe} from "./fuel-type.pipe";
import {YesOrNoPipe} from "./yes-or-no.pipe";
import {FilterCollectionByProjectPipe} from "./filter-collection-by-project.pipe";
import {LocaleCurrencyPipe} from "./locale-currency.pipe";
import {DistanceUnitPipe} from "./distance-unit.pipe";
import {CurrentLocalePipe} from "./current-locale.pipe";
import {LastSegmentPipe} from "./last-segment.pipe";

@NgModule({
  imports: [],
  declarations: [SanitizeHtmlPipe, DateAgoPipe, TruncateOnWordPipe, MediaObjectPipe, OwnerRolePipe, PercentageCountPipe,
    UcFirstPipe, DurationFormatPipe, ParticipationPipe, SanitizeUrlPipe, ReactionStateClassMapPipe, TranslateSelectorPipe,
    ProjectUserCountPipe, FilterCollectionByTypePipe, RemoveHtmlTagsPipe, FuelTypePipe, YesOrNoPipe, FilterCollectionByProjectPipe,
    LocaleCurrencyPipe, DistanceUnitPipe, CurrentLocalePipe, LastSegmentPipe
  ],
  exports: [SanitizeHtmlPipe, DateAgoPipe, TruncateOnWordPipe, MediaObjectPipe, OwnerRolePipe, PercentageCountPipe, UcFirstPipe,
    DurationFormatPipe, ParticipationPipe, SanitizeUrlPipe, ReactionStateClassMapPipe, TranslateSelectorPipe,
    ProjectUserCountPipe, FilterCollectionByTypePipe, RemoveHtmlTagsPipe, FuelTypePipe, YesOrNoPipe, FilterCollectionByProjectPipe,
    LocaleCurrencyPipe, DistanceUnitPipe, CurrentLocalePipe, LastSegmentPipe
  ]
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
