<form *ngIf="data.selectedTab.type === SearchTabsEnum.EVENTS" [formGroup]="form" novalidate>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Lokalizacja" styleClass="pb-3">
        <p-autoComplete
          #marketplaceAddress
          formControlName="address"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [styleClass]="'mb-2'"
          [suggestions]="searchResults"
          (completeMethod)="searchPlaces($event)"
          (onSelect)="selectPlace($event)"
          [minLength]="2"
          [delay]="500"
          [multiple]="true"
          [optionLabel]="getAddrOptionLabel"
          [optionValue]="getAddrOptionValue"
          [forceSelection]="true"
        >
          <ng-template let-result pTemplate="item">
            <div class="text-white">{{ result.label }}</div>
          </ng-template>
          <ng-template let-result pTemplate="selectedItem">
            <div class="text-white">{{ result.label }}</div>
          </ng-template>
        </p-autoComplete>
        <app-map></app-map>
      </p-fieldset>
    </div>
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Odległość">
        <label><span> +{{ form.get('distance').value }} km</span></label>
        <br/>
        <br/>
        <p-slider formControlName="distance" [step]="10" [max]="5000" styleClass="w-full mb-2"></p-slider>
      </p-fieldset>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Hashtagi">
        <app-hashtags
          formControlName="hashtags"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [completeOnFocus]="true"
          [forceSelection]="true"
        >
          <div *ngIf="form.get('hashtags').invalid && form.get('hashtags').touched" class="app-form-error">
            <p>{{ form.get('hashtags')['errorMessage'] }}</p>
            <p *ngIf="form.get('hashtags').errors?.custom">{{ form.get('hashtags').errors.custom }}</p>
          </div>
        </app-hashtags>
      </p-fieldset>
    </div>
  </div>
</form>

<form *ngIf="data.selectedTab.type === SearchTabsEnum.MARKETPLACE" [formGroup]="form" novalidate>
  <div class="grid mt-0">
    <div class="col-12 m-0 p-0" [ngClass]="{ 'xl:col-6 px-2': data.selectedTab.filtersMode === 'advanced' }">
      <div class="grid">
        <div *ngIf="data.selectedTab.filtersMode === 'advanced'" class="col-12 lg:col-6 p-fluid">
          <div class="p-field">
            <p-multiSelect
              formControlName="project.vehicle.bodyType"
              filter="true"
              dataKey="@id"
              optionLabel="name"
              pAutoFocus="false"
              appendTo="body"
              [attr.inputmode]="'none'"
              [showClear]="true"
              [autofocus]="false"
              [autofocusFilter]="false"
              [autoOptionFocus]="false"
              [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.body_type' | translate"
              [options]="filtersDPS.bodyTypes"
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="col-12 lg:col-6 p-fluid">
          <div class="p-field">
            <p-multiSelect
              formControlName="project.vehicle.vehicleType"
              filter="true"
              dataKey="@id"
              optionLabel="name"
              pAutoFocus="false"
              appendTo="body"
              [attr.inputmode]="'none'"
              [showClear]="true"
              [autofocus]="false"
              [autofocusFilter]="false"
              [autoOptionFocus]="false"
              [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.vehicle_type' | translate"
              [options]="filtersDPS.vehicleTypes"
              (onChange)="seekMakes(vType.value)"
              #vType
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="col-12 lg:col-6 p-fluid">
          <div class="p-field">
            <p-multiSelect
              formControlName="project.vehicle.make"
              filter="true"
              dataKey="@id"
              optionLabel="name"
              [attr.inputmode]="'none'"
              [showClear]="true"
              [autofocus]="false"
              [autofocusFilter]="false"
              [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.make' | translate"
              [options]="filtersDPS.makes"
              (onChange)="seekModels(vType.value, make.value)"
              (onClear)="this.filtersDPS.disableRelatedChoices('project.vehicle.model', ['project.vehicle.model'])"
              #make
            >
            </p-multiSelect>
          </div>
        </div>
        <div class="col-12 lg:col-6 p-fluid">
          <div class="p-field">
            <p-multiSelect
              formControlName="project.vehicle.model"
              filter="true"
              dataKey="@id"
              optionLabel="name"
              [attr.inputmode]="'none'"
              [showClear]="true"
              [autofocus]="false"
              [autofocusFilter]="false"
              [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.model' | translate"
              [options]="filtersDPS.models"
              #model
            >
            </p-multiSelect>
          </div>
        </div>
        <div *ngIf="data.selectedTab.filtersMode === 'advanced'" class="col-12 lg:col-6 p-fluid">
          <div class="p-field">
            <p-multiSelect
              formControlName="project.vehicle.engine.engineType"
              filter="true"
              dataKey="value"
              optionLabel="label"
              pAutoFocus="false"
              appendTo="body"
              [attr.inputmode]="'none'"
              [showClear]="true"
              [autofocus]="false"
              [autofocusFilter]="false"
              [autoOptionFocus]="false"
              [placeholder]="'common.fuel_type' | translate"
              [options]="filtersDPS.fuelTypes"
            >
            </p-multiSelect>
          </div>
        </div>

        <div class="col-12 lg:col-6 p-fluid">
          <div class="grid">
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    mode="decimal"
                    formControlName="project.vehicle.bodyGen.yearFrom[gte]"
                    [showClear]="true"
                    [min]="1900"
                    [max]="currentDate|date:'YYYY'"
                    [maxlength]="4"
                    [useGrouping]="false"
                    [suffix]="' ' + ('common.year_short'|translate)"
                  />
                  <label>{{ 'common.year_from'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    mode="decimal"
                    formControlName="project.vehicle.bodyGen.yearTo[lte]"
                    [showClear]="true"
                    [min]="1900"
                    [max]="currentDate|date:'YYYY'"
                    [maxlength]="4"
                    [useGrouping]="false"
                    [suffix]="' ' + ('common.year_short'|translate)"
                  />
                  <label>{{ 'common.year_to'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 p-fluid">
          <div class="grid">
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    mode="decimal"
                    formControlName="project.attributes.mileage[gte]"
                    [showClear]="true"
                    [suffix]="' ' + (''|distanceUnit)"
                    [locale]="''|currentLocale"
                  />
                  <label>{{ 'common.mileage_from'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    mode="decimal"
                    formControlName="project.attributes.mileage[lte]"
                    [showClear]="true"
                    [suffix]="' ' + (''|distanceUnit)"
                    [locale]="''|currentLocale"
                  />
                  <label>{{ 'common.mileage_to'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 p-fluid">
          <div class="grid">
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    formControlName="priceGross[gte]"
                    mode="currency"
                    [showClear]="true"
                    [currency]="''|localeCurrency"
                    [locale]="''|currentLocale"
                  />
                  <label>{{ 'common.price_gross_from'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
            <div class="col-6 p-fluid">
              <div class="p-field">
                <p-floatLabel>
                  <p-inputNumber
                    formControlName="priceGross[lte]"
                    mode="currency"
                    [showClear]="true"
                    [currency]="''|localeCurrency"
                    [locale]="''|currentLocale"
                  />
                  <label>{{ 'common.price_gross_to'|translate }}</label>
                </p-floatLabel>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="data.selectedTab.filtersMode === 'advanced'">
          <div class="col-12 lg:col-6 p-fluid">
            <div class="grid">
              <div class="col-6 p-fluid">
                <div class="p-field">
                  <p-floatLabel>
                    <p-inputNumber
                      formControlName="project.vehicle.engine.capacityCm3[gte]"
                      mode="decimal"
                      [showClear]="true"
                    />
                    <label>{{ 'common.engine_capacity_from'|translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
              <div class="col-6 p-fluid">
                <div class="p-field">
                  <p-floatLabel>
                    <p-inputNumber
                      formControlName="project.vehicle.engine.capacityCm3[lte]"
                      mode="decimal"
                      [showClear]="true"
                    />
                    <label>{{ 'common.engine_capacity_to'|translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-6 p-fluid">
            <div class="grid">
              <div class="col-6 p-fluid">
                <div class="p-field">
                  <p-floatLabel>
                    <p-inputNumber
                      formControlName="project.vehicle.engine.enginePowerBHP[gte]"
                      mode="decimal"
                      [showClear]="true"
                    />
                    <label>{{ 'common.engine_power_from'|translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
              <div class="col-6 p-fluid">
                <div class="p-field">
                  <p-floatLabel>
                    <p-inputNumber
                      formControlName="project.vehicle.engine.enginePowerBHP[lte]"
                      mode="decimal"
                      [showClear]="true"
                    />
                    <label>{{ 'common.engine_power_to'|translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="grid" *ngIf="data.selectedTab.filtersMode === 'advanced'">
        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.financial_options'|translate }}</span>
            <div class="grid pt-3 p-2">
              <div class="col-12">
                <div class="grid align-items-center">
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="invoiceVat"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'common.invoice_vat'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="invoiceVatMargin"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'common.invoice_vat_margin'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="toNegotiation"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.price_to_negotiation'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          #leasingAssignment
                          formControlName="leasingAssignment"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.leasing_assignment'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
              <div *ngIf="leasingAssignment.checked()" class="col-12">
                  <span
                    class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'shared.modal.modal_service.add_vehicle_advertisement.leasing_assignment'|translate }}</span>
                <div class="grid mt-3">
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingInitialFee[gte]" pInputText/>
                      <label>{{ 'common.initial_fee_from'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingInitialFee[lte]" pInputText/>
                      <label>{{ 'common.initial_fee_to'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingMonthlyInstallment[gte]" pInputText/>
                      <label>{{ 'common.monthly_leasing_installment_from'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingMonthlyInstallment[lte]" pInputText/>
                      <label>{{ 'common.monthly_leasing_installment_to'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingRemainingInstallments[gte]" pInputText/>
                      <label>{{ 'common.remaining_installments_from'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingRemainingInstallments[lte]" pInputText/>
                      <label>{{ 'common.remaining_installments_to'|translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingBuyoutValue[gte]" pInputText/>
                      <label>{{ 'common.buyout_value_from' | translate }}</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="p-fluid p-float-label">
                      <input type="text" formControlName="leasingBuyoutValue[lte]" pInputText/>
                      <label>{{ 'common.buyout_value_to' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.colour_options'|translate }}</span>
            <div class="grid p-2">
              <div class="col-12 md:col-6">
                <div class="p-fluid p-float-label">
                  <p-multiSelect
                    formControlName="project.attributes.originalColour"
                    filter="true"
                    dataKey="value"
                    optionLabel="label"
                    pAutoFocus="false"
                    appendTo="body"
                    [showClear]="true"
                    [autofocus]="false"
                    [autofocusFilter]="false"
                    [autoOptionFocus]="false"
                    [placeholder]="('common.choose_or_search'|translate)"
                    [options]="filtersDPS.colours | translateCollectionOrItem"
                  >
                  </p-multiSelect>
                  <label>{{ 'common.original_colour'|translate }}</label>
                </div>
                <div class="p-fluid p-float-label mt-4"
                     *ngIf="form.controls['project.attributes.originalPaintDiffersCurrent'].value">
                  <p-multiSelect
                    formControlName="project.attributes.currentColour"
                    filter="true"
                    dataKey="value"
                    optionLabel="label"
                    pAutoFocus="false"
                    appendTo="body"
                    [showClear]="true"
                    [autofocus]="false"
                    [autofocusFilter]="false"
                    [autoOptionFocus]="false"
                    [placeholder]="('common.choose_or_search'|translate)"
                    [options]="filtersDPS.colours | translateCollectionOrItem"
                  >
                  </p-multiSelect>
                  <label>{{ 'common.colour'|translate }}</label>
                </div>
              </div>
              <div class="col-12 md:col-6">
                <p-panel>
                  <div class="grid align-items-center">
                    <div class="col-12 p-fluid">
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.originalPaintDiffersCurrent"
                          [binary]="true"
                          [falseValue]="null"
                          [inputId]="'original_colour_differs_current'"
                        ></p-checkbox>
                        <label
                          [for]="'original_colour_differs_current'">{{ 'common.color_can_differ'|translate }}</label>
                      </div>
                    </div>
                    <div class="col-12 p-fluid">
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.hasPaintCoverage"
                          [binary]="true"
                          [falseValue]="null"
                          [inputId]="'has_paint_coverage'"
                        ></p-checkbox>
                        <label [for]="'has_paint_coverage'">{{ 'common.has_paint_coverage'|translate }}</label>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="col-12" *ngIf="form.controls['project.attributes.hasPaintCoverage'].value">
                <span
                  class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.paint_coverage'|translate }}</span>
                <div class="grid mt-3 align-items-center align-self-stretch">
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.hasPpfWrapCoverage"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.ppf_wrap_coverage'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.hasOtherWrapCoverage"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.other_wrap_coverage'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.hasOtherPaintCoverage"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.other_paint_coverage'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.characteristics'|translate }}</span>
            <div class="grid p-2">
              <div class="col-12">
                <div class="grid align-items-center">
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.imported"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.imported'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.accidentFree"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.accident_free'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.damaged"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.damaged'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.rightSideSteeringWheel"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.right_side_steering_wheel'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.registeredInCountry"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.registered_in_current_country'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.asoService"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.aso_service'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.registeredAsAntique"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.registered_as_antique'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.mechanicalTuning"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.mechanical_tuning'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.visualTuning"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.visual_tuning'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                  <div class="col-6 p-fluid">
                    <p-panel>
                      <div class="p-field flex gap-5 align-items-center">
                        <p-checkbox
                          formControlName="project.attributes.truckApproval"
                          [binary]="true"
                          [falseValue]="null"
                        ></p-checkbox>
                        <label>{{ 'shared.modal.modal_service.add_vehicle_advertisement.truck_approval'|translate }}</label>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div *ngIf="data.selectedTab.filtersMode === 'advanced'" class="col-12 xl:col-6 m-0 p-0 px-2">
      <div class="grid">
        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.location'|translate }}</span>
            <div class="grid p-2 px-3">
              <div class="col-12 xl:col-6 p-fluid">
                <div class="p-field">
                  <p-autoComplete
                    #marketplaceAddress
                    formControlName="address"
                    [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
                    [styleClass]="'mb-2'"
                    [suggestions]="searchResults"
                    (completeMethod)="searchPlaces($event)"
                    (onSelect)="selectPlace($event)"
                    [minLength]="2"
                    [delay]="500"
                    [multiple]="true"
                    [optionLabel]="getAddrOptionLabel"
                    [optionValue]="getAddrOptionValue"
                    [forceSelection]="true"
                  >
                    <ng-template let-result pTemplate="item">
                      <div class="text-white">{{ result.label }}</div>
                    </ng-template>
                    <ng-template let-result pTemplate="selectedItem">
                      <div class="text-white">{{ result.label }}</div>
                    </ng-template>
                  </p-autoComplete>
                </div>
              </div>
              <div class="col-12 xl:col-6 p-fluid">
                <div class="p-field">
                  <label><span> +{{ form.get('distance').value }} km</span></label>
                  <br/>
                  <br/>
                  <p-slider formControlName="distance" [step]="5" [min]="5" [max]="1000" styleClass="w-full mb-2"
                            (keyup)="updateRadius()" (onSlideEnd)="updateRadius()"></p-slider>
                </div>
              </div>
              <div class="col-12">
                <app-map [zoom]="11" [allowScrollWheelZoom]="true" [draggable]="true"></app-map>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.doors_and_seats'|translate }}</span>
            <div class="grid grid-nogutter pb-2 px-3 flex align-items-center">
              <div class="col-2">{{ 'common.num_of_doors'|translate }}</div>
              <div class="col pl-3 py-2 flex flex-wrap gap-3">
                <ng-container *ngFor="let number of [1,2,3,4,5,6]; let i = index">
                  <div class="flex align-items-center">
                    <p-checkbox
                      [formControl]="getNumOfDoorsControl()"
                      [value]="number"
                      inputId="numOfDoors-{{number}}"/>
                    <label for="numOfDoors-{{number}}" class="ml-2">
                      {{ number }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="grid grid-nogutter pb-2 px-3 flex align-items-center">
              <div class="col-2">{{ 'common.num_of_seater'|translate }}</div>
              <div class="col pl-3 py-2 flex flex-wrap gap-3">
                <ng-container *ngFor="let number of [1,2,3,4,5,6,7,8,9]; let i = index">
                  <div class="flex align-items-center">
                    <p-checkbox
                      [formControl]="getNumOfSeaterControl()"
                      [value]="number"
                      inputId="numOfSeater-{{number}}"/>
                    <label for="numOfSeater-{{number}}" class="ml-2">
                      {{ number }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="border-1 surface-border border-round mb-4">
              <span
                class="text-900 font-bold block border-bottom-1 surface-border p-3">{{ 'common.equipment'|translate }}</span>
            <div class="grid grid-nogutter pb-2 px-3">
              <ng-container *ngFor="let equipmentCategory of filtersDPS.equipmentCategoryCollection; let ci = index;">
                <div class="col-12 py-2">
                  <p-accordion>
                    <p-accordionTab [header]="equipmentCategory.name">
                      <div class="grid">
                        <ng-container *ngFor="let equipment of equipmentCategory.equipment; let i = index;">
                          <div class="col-12 md:col-6">
                            <div class="p-field flex gap-5 align-items-center">
                              <p-checkbox
                                #equipment
                                [formControl]="getEquipmentControl()"
                                [value]="equipment['@id']"
                                [name]="'eq_cat_' + ci + '_equipment_' + i"
                                (onChange)="equipment.selected = $event.checked"
                                [inputId]="'eq_cat_' + ci + '_equipment_' + i"
                              ></p-checkbox>
                              <label [for]="'eq_cat_' + ci + '_equipment_' + i">{{ equipment.name }}</label>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

