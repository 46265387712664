import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Menu} from "primeng/menu";

@Component({
  selector: 'app-end-cap',
  templateUrl: './end-cap.component.html',
  styleUrls: ['./end-cap.component.scss'],
})
export class EndCapComponent implements OnInit {
  get pointerClass(): string | object {
    return this._pointerClass;
  }

  @Input() set pointerClass(value: string | object) {
    this._pointerClass = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }


  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  get showLoader(): boolean {
    return this._showLoader;
  }

  @Input() set showLoader(value: boolean) {
    this._showLoader = value;
  }

  get secondButtonPointerClass() {
    return this._secondButtonPointerClass;
  }

  @Input() set secondButtonPointerClass(value: string|object) {
    this._secondButtonPointerClass = {...this._pointerClass as object, 'p-button-icon-only': true, ...value as object};
  }

  @Input() wrapperClass?: string;
  @Input() label: string;
  @Input() secondButtonLabel: string;
  private _pointerClass?: string|object;
  private _secondButtonPointerClass?: string|object;
  private _disabled?: boolean = false;
  private _showLoader?: boolean;
  @Input() icon?: string;
  @Input() secondButtonIcon?: string = 'pi pi-angle-down';
  @Input() secondButton?: boolean = false;
  @Input() menuItems: MenuItem[] = [];
  @Output() onClickTrigger: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onSecondButtonClickTrigger: EventEmitter<Event> = new EventEmitter<Event>();
  public hovered: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  triggerClick(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.onClickTrigger.emit(event);
  }

  triggerSecondButtonClick(event: Event, menu: Menu = null): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.onSecondButtonClickTrigger.emit(event);

    if (menu) {
      menu.toggle(event);
    }
  }
}
