<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}"
     [ngStyle]="style" [class]="styleClass">
  <div style="height: 10rem; width: 100%; position: relative"
       [ngStyle]="currentBackgroundStyles"
  >
    <a class="layout-inline-menu-action flex p-dir-row align-items-center" style="height: 100%;"
       [ngClass]="appMain.isHorizontal() ? 'p-3 p-lg:col-1 py-3' : 'p-3'" (click)="onClick($event)"
       [pTooltip]="user.username" [tooltipDisabled]="isTooltipDisabled">
      <div style="padding-top: 5px">
        <app-thumbnail
          [imgSrc]="user.avatar | mediaObject" [circle]="true" [bordered]="true"
          stylesList="width: 62px; height: 62px;"
        ></app-thumbnail>
      </div>
      <span class="flex flex-column" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
            <span class="font-bold">{{ user?.username }}</span>
            <small>{{ user?.email }}</small>
        </span>
      <!--      <i class="layout-inline-menu-icon pi pi-angle-down"-->
      <!--         [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}"></i>-->
    </a>
  </div>


  <ul class="layout-inline-menu-action-panel"
      (click)="onClickNavigationElement()"
      [@menu]="appMain.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">
    <li class="layout-menuitem-text" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      {{ 'common.shortcuts' | translate }}
    </li>
    <li class="layout-inline-menu-action-item" *ngxPermissionsOnly="['ROLE_ADMIN']"
        [pTooltip]="'shared.inline_menu.admin_dashboard' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/admin']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-lock"></i>
        <span>{{ 'shared.inline_menu.admin_dashboard' | translate }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.garage' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'garage']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-warehouse"></i>
        <span>{{ 'shared.inline_menu.garage' | translate }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'profile']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-user pi-fw"></i>
        <span>{{ 'shared.inline_menu.profile' | translate }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.settings' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'settings']" class="flex flex-row align-items-center"
         [attr.tabindex]="tabIndex">
        <i class="pi pi-cog pi-fw"></i>
        <span>{{ 'shared.inline_menu.settings' | translate }}</span>
      </a>
    </li>
    <li class="layout-menuitem-text" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      {{ 'common.marketplace' | translate }}
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'offers']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-gauge pi-fw" pBadge value="2" [badgeDisabled]="true"></i>
        <span>{{ 'shared.inline_menu.my_advertisements' | translate }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'saved-offers']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-heart pi-fw" pBadge value="2" [badgeDisabled]="true"></i>
        <span>{{ 'shared.inline_menu.followed_advertisements' | translate }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.profile' | translate"
        [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'saved-search-results']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-search pi-fw" pBadge value="2" [badgeDisabled]="true"></i>
        <span>{{ 'common.saved_search_results' | translate }}</span>
      </a>
    </li>
  </ul>
</div>
