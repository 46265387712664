import {Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {FilterParameterApiModel} from "../../api/models/filter-parameter.api.model";
import {Params} from "@angular/router";

@Injectable()
export class QueryParamsService {
  constructor(
  ) {
  }

  public applyParameters(queryParams: QueryParamsApiModel = new QueryParamsApiModel()): HttpParams {
    let params = new HttpParams();
    if (queryParams.filters) {
      let i = 0;
      let type = '';
      for (const filter of queryParams.filters) {
        type = filter.type === 'array_with_key' ? `[${filter.key}]` : type;
        type = filter.type === 'array' ? '[]' : type;
        type = filter.type === 'single' ? '' : type;
        params = params.append(`${filter.name}${type}`, filter.value);
        i++;
      }
    }
    if (queryParams.orderDirection) {
      if (typeof queryParams.orderDirection === 'number') {
        QueryParamsService.mapOrderDirection(queryParams);
      }
      params = params.append('orderDirection', queryParams.orderDirection);
    }
    if (queryParams.page) {
      params = params.append('page', String(queryParams.page));
    }
    if (typeof queryParams.pagination !== 'undefined') {
      params = params.append('pagination', Boolean(queryParams.pagination));
    }
    if (queryParams.limit !== null) {
      params = params.append('limit', String(queryParams.limit));
    }
    return params;
  }

  private static mapOrderDirection(queryParams: QueryParamsApiModel): void {
    switch (queryParams.orderDirection) {
      case -1:
        queryParams.orderDirection = 'DESC';
        break;
      case 1:
        queryParams.orderDirection = 'ASC';
        break;
      default:
        queryParams.orderDirection = 'DESC';
    }
  }

  private parseValue(value: any): any {
    if (typeof value !== 'string') {
      return JSON.stringify(value);
    }
    return value;
  }

  public static updateQueryParametersFromSnapshot(queryParams: QueryParamsApiModel, params: Params): void {
    const queryParamsSnapshot = params;
    queryParams.filters = [];
    Object.keys(queryParamsSnapshot).forEach((key: string) => {
      if (queryParamsSnapshot[key] instanceof Array) {
        queryParamsSnapshot[key].forEach((item: string) => {
          queryParams.filters.push(new FilterParameterApiModel(key.replace('[]', ''), item, 'array'));
        })
      } else {
        queryParams.filters.push(new FilterParameterApiModel(key, queryParamsSnapshot[key], 'single'));
      }
    });
  }

  public static getQueryParametersFromHttpParams(params: HttpParams): Params {
    const queryParams: Params = {}
    params.keys().forEach(k => {
      const keyInterpretationAsArray = k.includes('[]');
      const normalizedKey = k.replace('[]', '');
      if (keyInterpretationAsArray) {
        queryParams[normalizedKey] = params.getAll(k);
        return;
      }

      queryParams[`${normalizedKey}`] = params.get(k);
    });

    return queryParams;
  }

  public static getNormalizedQueryParametersFromSessionStorage(): Params {
    const filters = {};
    const queryParams = QueryParamsService.mapJsonValues(JSON.parse(sessionStorage.getItem('queryParams')) || {});

    Object.keys(queryParams).forEach((key: string) => {
      const normalizedKey = key.replace('[]', '');
      if (queryParams[key] instanceof Array) {
        filters[`${normalizedKey}`] = [];
        queryParams[key].forEach((item: string) => {
          filters[`${normalizedKey}`].push(item);
        });
      } else {
        filters[`${normalizedKey}`] = queryParams[key];
      }
    });

    return filters;
  }

   private static mapJsonValues<T extends Record<string, any>>(jsonObject: T): Record<string, any> {
    return Object.fromEntries(
      Object.entries(jsonObject).map(([key, value]) => {
        if (Array.isArray(value)) {
          const mappedArray = value.map(QueryParamsService.mapValue);
          return [key, mappedArray.length === 1 && typeof mappedArray[0] === 'boolean' ? mappedArray[0] : mappedArray];
        }
        return [key, QueryParamsService.mapValue(value)];
      })
    );
  }

  private static mapValue(value: any): any {
    if (typeof value === "string") {
      if (value === "true") return true;
      if (value === "false") return false;
      if (!isNaN(Number(value))) return Number(value);
      try {
        const parsed = JSON.parse(value);
        if (Array.isArray(parsed)) return parsed;
      } catch {
        // ignore parse errors
      }
    }
    return value;
  }

  public static syncObjects<T extends Record<string, any>>(source: T, target: T): T {
    const result: Record<string, any> = {};

    // Add keys from `target` if they do not exist in `source`
    Object.keys(target).forEach(key => {
      if (
        typeof target[key] === "object" &&
        target[key] !== null &&
        !Array.isArray(target[key])
      ) {
        // Recursive synchronization for objects
        result[key] = this.syncObjects(source[key] || {}, target[key]);
      } else {
        // Copy the value from `target`
        result[key] = target[key];
      }
    });

    // Remove keys that exist in `source` but are missing in `target`
    Object.keys(source).forEach(key => {
      if (!(key in target)) {
        delete result[key];
      }
    });

    return result as T;
  }
}
