<form [formGroup]="form" (ngSubmit)="login()" novalidate>
  <h1 class="text-center m-1">
    <img src="assets/layout/images/wtg-logo-transparent-white.webp" alt="wtg watch this garage logo" class="app-logo">
    <small class="-mt-3" style="color: #4285F4">{{'auth.complete_oauth2_registration.title' | translate}}</small>
  </h1>
  <div class="col-12 p-fluid">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <input pInputText minLength="6" maxLength=20 data-error=" " data-success=" " type="text" id="username"
                   class="form-control" formControlName="username">
            <label for="username">{{'auth.registration.username' | translate}}</label>
          </span>
  </div>
  <div class="col-12 app-form-error"
       *ngIf="form.controls['username'].invalid && form.controls['username'].touched">
    <p *ngIf="form.controls['username'].errors.required">{{'auth.registration.validation.required_name' | translate}}</p>
    <p *ngIf="form.controls['username'].errors.minlength">{{'auth.registration.validation.min' | translate}}</p>
    <p *ngIf="form.controls['username'].errors.maxlength">{{'auth.registration.validation.max' | translate}}</p>
    <p *ngIf="form.controls['username'].errors.custom">{{form.controls['username'].errors.custom}}</p>
  </div>
  <div class="col-12 p-fluid">
    <i class="pi pi-thumbs-up ml-2 mr-3"></i>
    <p-checkbox [binary]="true" [formControl]="form.controls['terms']"
                [label]="'auth.registration.accept_terms' | translate">
    </p-checkbox>
  </div>
  <div class="col-12 app-form-error"
       *ngIf="form.controls['terms'].invalid && form.controls['terms'].touched">
    <div *ngIf="form.controls['terms'].errors.required">{{'auth.registration.validation.accept_terms' | translate}}</div>
    <div *ngIf="form.controls['terms'].errors.custom">{{form.controls['terms'].errors.custom}}</div>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            type="submit"
            icon="pi pi-plus"
            iconPos="left"
            class="p-button-primary p-button-outlined p-button-rounded"
            [disabled]="form.invalid"
            [label]="'common.go_forward' | translate">
    </button>
  </div>
</form>
