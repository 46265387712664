import {Injectable} from '@angular/core';
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {ProjectViewModel} from '../../features/user/garage/project.view-model';
import {UserRestService} from '../../api/services/user.rest.service';
import {ProjectRestService} from '../../api/services/project.rest.service';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {ResultListApiModel} from '../../api/models/result-list.api.model';
import {ProjectApiModel} from '../../api/models/project.api-model';
import {UserApiModel} from '../../api/models/user.api-model';
import {UserViewModel} from '../../features/user/settings/user.view-model';
import {QueryResourceInterface} from '../models/query-resource.interface';
import {MediaObjectViewModel} from '../models/media-object.view-model';
import {QueryParamsApiModel} from '../../api/models/query-params-api.model';
import {FilterParameterApiModel} from '../../api/models/filter-parameter.api.model';
import {MediaObjectApiModel} from '../../api/models/media-object.api-model';
import {MediaObjectRestService} from '../../api/services/media-object.rest.service';
import {VehicleApiModel} from '../../api/models/vehicle.api-model';
import {VehicleViewModel} from '../../features/user/garage/vehicle.view-model';
import {VehicleTypeViewModel} from '../models/vehicle-type.view-model';
import {VehicleTypeApiModel} from '../../api/models/vehicle-type.api-model';
import {VehicleRestService} from '../../api/services/vehicle.rest.service';
import {VehicleTypeRestService} from '../../api/services/vehicle-type.rest.service';
import {PersonalInformationRestService} from '../../api/services/personal-information.rest.service';
import {PersonalInformationApiModel} from '../../api/models/personal-information.api.model';
import {PersonalInformationViewModel} from '../../features/user/settings/personal-information/personal-information-view-model';
import {SearchLogRestService} from '../../api/services/search-log.rest.service';
import {CurrentUserViewModel} from '../models/current-user.view-model';
import {SearchLogApiModel} from '../../api/models/search-log.api-model';
import {plainToClass} from 'class-transformer';
import {MediaObjectPipe} from '../pipes/media-object.pipe';
import {EventRestService} from '../../api/services/event.rest.service';
import {EventApiModel} from '../../api/models/event.api.model';
import {ParticipantApiModel} from '../../api/models/participant.api.model';
import {ArrayHelper} from '../helpers/array.helper';
import {OffersDataProviderService} from "../../shared/marketplace/offers.data-provider.service";
import {MarketplaceAdvertisementApiModel} from "../../api/models/marketplace-advertisement.api.model";



@Injectable()
export class QueryService {
  public totalItems: {[key: string]: number} = {all: 0, projects: 0, users: 0, events: 0, marketplace: 0};
  public projects: QueryResourceInterface[] = [];
  public users: QueryResourceInterface[] = [];
  public events: QueryResourceInterface[] = [];
  public marketplace: QueryResourceInterface[] = [];
  public resources: QueryResourceInterface[] = [];
  public resultSubject$: Subject<string> = new Subject();
  public hasLastQueriedResources: boolean;
  public loading: boolean = true;


  constructor(
    private readonly projectRS: ProjectRestService,
    private readonly userRS: UserRestService,
    private readonly eventRS: EventRestService,
    private readonly vehicleRS: VehicleRestService,
    private readonly vehicleTypeRS: VehicleTypeRestService,
    private readonly mediaObjectRS: MediaObjectRestService,
    private readonly personalInformationRS: PersonalInformationRestService,
    private readonly searchLogRS: SearchLogRestService,
    private readonly offersDPS: OffersDataProviderService
  ) {
  }

  private static sortByName(a: QueryResourceInterface, b: QueryResourceInterface): number {
    return ('' + a.name).localeCompare(b.name);
  }

  public createLog(user: CurrentUserViewModel, queryResource: QueryResourceInterface): Observable<any> {
    const searchLogAM = plainToClass(SearchLogApiModel, {
      user: user['@id'],
      type: queryResource.type,
      slug: queryResource.slug
    });
    return this.searchLogRS.create(searchLogAM).pipe(mergeMap(() => {
      return this.fetchSearchLogResources(user);
    }));
  }

  public fetchSearchLogResources(userVM: CurrentUserViewModel): Observable<(ProjectViewModel[]|UserViewModel[])[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('id', userVM.id, 'single'));
    return this.searchLogRS.getCollection(queryParams).pipe(switchMap((searchLogRL: ResultListApiModel<SearchLogApiModel>) => {
      this.hasLastQueriedResources = true;
      if (searchLogRL.total) {
        const projectQueryParams = new QueryParamsApiModel();
        const userQueryParams = new QueryParamsApiModel();
        const eventQueryParams = new QueryParamsApiModel();
        const offerQueryParams = new QueryParamsApiModel();
        searchLogRL.records.forEach(e => {
          switch (e.type) {
            case 'user': userQueryParams.filters.push(new FilterParameterApiModel('slug', e.slug, 'array')); break;
            case 'project': projectQueryParams.filters.push(new FilterParameterApiModel('slug', e.slug, 'array')); break;
            case 'event': eventQueryParams.filters.push(new FilterParameterApiModel('slug', e.slug, 'array')); break;
            case 'marketplace': offerQueryParams.filters.push(new FilterParameterApiModel('slug', e.slug, 'array')); break;
          }
        });

        return forkJoin([
          projectQueryParams.filters.length ? this.projectRS.getCollection(projectQueryParams) : of(new ResultListApiModel<ProjectApiModel>(ProjectApiModel)),
          userQueryParams.filters.length ? this.userRS.getCollection(userQueryParams) : of (new ResultListApiModel<UserApiModel>(UserApiModel)),
          eventQueryParams.filters.length ? this.eventRS.getCollection(eventQueryParams) : of (new ResultListApiModel<EventApiModel>(EventApiModel)),
          offerQueryParams.filters.length ? this.offersDPS.getCollection(offerQueryParams, false, null) : of (new ResultListApiModel<MarketplaceAdvertisementApiModel>(MarketplaceAdvertisementApiModel))
        ]).pipe(mergeMap(([projectsAM, usersAM, eventsAM, offersAM]: [ResultListApiModel<ProjectApiModel>, ResultListApiModel<UserApiModel>, ResultListApiModel<EventApiModel>,  ResultListApiModel<MarketplaceAdvertisementApiModel>]) => {
          return this.forkDeps([projectsAM, usersAM, eventsAM, offersAM]).pipe(tap(() => {
            this.resources = searchLogRL.records.map(e => {
              const queryResource = this.resources.find(e1 => e1.slug === e.slug);
              queryResource.searchLog = e;
              return queryResource;
            });
          }));
        }));
      } else {
        return of([]);
      }
    }));
  }

  public fetchResourcesFromQuerySubject(): Observable<(ProjectViewModel[]|UserViewModel[]|EventApiModel[]|MarketplaceAdvertisementApiModel[])[]> {
    return this.resultSubject$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((query: string) => {
        console.log(query, 'query');
        this.loading = true;
        return this.fetchResources(query);
      })).pipe(tap(() => this.loading = false));
  }

  public removeSearchLog(queryResourceInterface: QueryResourceInterface) {
    return this.searchLogRS.delete(queryResourceInterface.searchLog);
  }

  public fetchResources(query: string, page: number = 1): Observable<(ProjectViewModel[]|UserViewModel[]|EventApiModel[]|MarketplaceAdvertisementApiModel[])[]> {
    if (page === 1) {
      this.resetCachedResults();
    }
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('info.firstName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('info.lastName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('username', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicle.vehicleIdentity', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('alias', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('user.username', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('user.info.firstName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('user.info.lastName', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('name', query, 'single'));
    queryParams.filters.push(new FilterParameterApiModel('title', query, 'single'));
    queryParams.page = page;
    return forkJoin([
      this.projectRS.getCollection(queryParams),
      this.userRS.getCollection(queryParams),
      this.eventRS.getCollection(queryParams),
      this.offersDPS.getCollection(queryParams, false, null)
    ]).pipe(mergeMap(([projectsAM, usersAM, eventsAM, advertisementsAM]: [ResultListApiModel<ProjectApiModel>, ResultListApiModel<UserApiModel>, ResultListApiModel<EventApiModel>, ResultListApiModel<MarketplaceAdvertisementApiModel>]) => {
      this.totalItems.projects = projectsAM.total;
      this.totalItems.users = usersAM.total;
      this.totalItems.events = eventsAM.total;
      this.totalItems.marketplace = advertisementsAM.total;
      this.totalItems.all = projectsAM.total + usersAM.total + eventsAM.total;
      return this.forkDeps([projectsAM, usersAM, eventsAM, advertisementsAM]);
    }), tap(([projectsVM, usersVM, eventsAM, offersAM]: [ProjectViewModel[], UserViewModel[], EventApiModel[], MarketplaceAdvertisementApiModel[]]) => {
      this.hasLastQueriedResources = false;
    }));
  }

  private forkDeps([projectsAM, usersAM, eventsAM, advertisementsAM]: [ResultListApiModel<ProjectApiModel>, ResultListApiModel<UserApiModel>, ResultListApiModel<EventApiModel>, ResultListApiModel<MarketplaceAdvertisementApiModel>]): Observable<(ProjectViewModel[]|UserViewModel[]|EventApiModel[]|MarketplaceAdvertisementApiModel[])[]> {
    return forkJoin([
      this.getVehicleCollection(projectsAM.records),
      this.getMediaObjectCollection([...projectsAM.records, ...usersAM.records]),
      this.getUsersAMCollection([...projectsAM.records.map((e: ProjectApiModel) => e.user), ...ArrayHelper.flattenArray(eventsAM.records.map((e: EventApiModel) => e.organizers.map(o => o.data)))])
    ]).pipe(mergeMap( ([vehicleVMs, mediaObjectVMs, dependantUserAMs]: [VehicleViewModel[], MediaObjectViewModel[], ResultListApiModel<UserApiModel>]) => {
      return this.getPersonalInformationCollection([...usersAM.records, ...dependantUserAMs.records]).pipe(map((personalInfoVMs: PersonalInformationViewModel[]) => {
        this.projects = [...this.projects, ...projectsAM.records.map((e: ProjectApiModel) => {
          const owner = new UserViewModel(dependantUserAMs.records.find(u => u['@id'] ===  e.user));
          owner.info = personalInfoVMs.find(p => p['@id'] ===  owner.info);
          return Object.assign({
            name: vehicleVMs.find(v => v['@id'] ===  e.vehicle)?.fullVehicleIdentity,
            alias: e.alias,
            uri: '/' + owner.slug + `/garage/` + e.slug,
            slug: e.slug,
            users: [owner],
            subject: e,
            thumbnail: MediaObjectPipe.transformToLink(mediaObjectVMs.find(m => m['@id'] ===  e.theme), {defaultImageType: 'car'}),
            type: 'project',
            isPlaceholder: false
          });
        })];
        this.users = [...this.users, ...usersAM.records.map((e: UserApiModel) => {
          const user = new UserViewModel(e);
          user.info = personalInfoVMs.find(p => p['@id'] ===  e.info);
          return Object.assign({
            name: user.info.firstName + ' ' + user.info.lastName,
            alias: e.username,
            uri: '/' + e.slug,
            slug: e.slug,
            users: [e],
            subject: e,
            thumbnail: mediaObjectVMs.find(m => m['@id'] ===  e.avatar)?.hyperlinks[0]?.uri || 'assets/img/profile-bg-dark.png',
            type: 'user',
            isPlaceholder: false
          });
        })];
        this.events = [...this.events, ...eventsAM.records.map((e: EventApiModel) => {
          return Object.assign({
            name: e.name,
            alias: null,
            uri: '/events/' + e.slug,
            slug: e.slug,
            subject: e,
            users: (e.organizers as ParticipantApiModel[]).map((o: ParticipantApiModel) => dependantUserAMs.records.find(e => e['@id'] === o.data)),
            thumbnail: (e.cover as MediaObjectApiModel)?.hyperlinks[0]?.uri || 'assets/img/profile-bg-dark.png',
            type: 'event',
            isPlaceholder: false
          });
        })];
        this.marketplace = [...this.marketplace, ...advertisementsAM.records.map((e: MarketplaceAdvertisementApiModel) => {
          return Object.assign({
            name: e.title,
            alias: null,
            uri: '/marketplace/offer/' + e.slug,
            slug: e.slug,
            subject: e,
            users: [e.createdBy],
            thumbnail: (e.media[0] as MediaObjectApiModel)?.hyperlinks[0]?.uri || 'assets/img/profile-bg-dark.png',
            type: 'marketplace',
            isPlaceholder: false
          });
        })];
        this.resources = [...this.users, ...this.projects, ...this.events, ...this.marketplace].sort(QueryService.sortByName);

        return [
          projectsAM.records.map((projectAM: ProjectApiModel) => new ProjectViewModel(projectAM)),
          usersAM.records.map((userAM: UserApiModel) => new UserViewModel(userAM)),
          eventsAM.records,
          advertisementsAM.records,
        ];
      }));
    }));
  }

  private getVehicleCollection(projectAMs: ProjectApiModel[]): Observable<VehicleViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = projectAMs.map(e => new FilterParameterApiModel('id', e.vehicle));
    return this.vehicleRS.getVehicleCollection(params)
      .pipe(mergeMap((resultListAM: ResultListApiModel<VehicleApiModel>) => {
        return this.getVehicleTypeCollection().pipe(map((vehicleTypeVMs: VehicleTypeViewModel[]) => {
          return resultListAM.records.map((vehicleAM: VehicleApiModel) => {
            const vehicleVM = new VehicleViewModel(vehicleAM);
            vehicleVM.vehicleType = vehicleTypeVMs.find(e => e['@id'] === vehicleAM.vehicleType);
            return vehicleVM;
          });
        }));
      }));
  }

  private getVehicleTypeCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<VehicleTypeViewModel[]> {
    return this.vehicleTypeRS.getVehicleTypeCollection(queryParamsApiModel)
      .pipe(map((resultListAM: ResultListApiModel<VehicleTypeApiModel>) => {
        return resultListAM.records.map((vehicleAM: VehicleTypeApiModel) => new VehicleTypeViewModel(vehicleAM));
      }));
  }

  private getUsersAMCollection(userLinks: string[]): Observable<ResultListApiModel<UserApiModel>> {
    const params = new QueryParamsApiModel();
    params.filters = userLinks.map(e => new FilterParameterApiModel('slug', e.split('/').pop()));
    return this.userRS.getCollection(params);
  }

  private getPersonalInformationCollection(resultAMs: UserApiModel[]): Observable<PersonalInformationViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = resultAMs.map(e => new FilterParameterApiModel('id', e.info));
    return this.personalInformationRS.getCollection(params)
      .pipe(map((resultListAM: ResultListApiModel<PersonalInformationApiModel>) => {
        return resultListAM.records.map((personalInformationAM: PersonalInformationApiModel) => new PersonalInformationViewModel(personalInformationAM));
      }));
  }

  private getMediaObjectCollection(resultAMs: (ProjectApiModel|UserApiModel)[]): Observable<MediaObjectViewModel[]> {
    const params = new QueryParamsApiModel();
    params.filters = resultAMs
      .filter((e: ProjectApiModel|UserApiModel) => typeof e['theme'] === 'string' || typeof e['avatar'] === 'string')
      .map(e => new FilterParameterApiModel('id', e['theme'] || e['avatar']));
    if (params.filters.length) {
      return this.mediaObjectRS.getMediaObjectCollection(params)
        .pipe(map((resultListAM: ResultListApiModel<MediaObjectApiModel>) => {
          return resultListAM.records.map((mediaObjectAM: MediaObjectApiModel) => new MediaObjectViewModel(mediaObjectAM));
        }));
    }

    return of<MediaObjectViewModel[]>([]);
  }

  public resetCachedResults(): void {
    this.resources = [];
    this.users = [];
    this.events = [];
    this.projects = [];
    this.marketplace = [];
  }
}
