<h1 class="text-center m-1">
  <img src="assets/layout/images/wtg-logo-transparent-white.webp" alt="wtg watch this garage logo" class="app-logo">
  <small class="-mt-3" style="color: #4285F4">{{'auth.registration.confirmation.title' | translate}}</small>
</h1>
<ng-container *ngIf="success; else processing">
  <p class="text-center">{{message}}</p>
  <div class="d-flex justify-content-center">
    <button pButton pRipple
            [routerLink]="['/login']"
            type="button"
            icon="pi pi-save"
            iconPos="left"
            class="p-button-primary p-button-outlined p-button-rounded"
            [label]="'common.button.sign_up' | translate"
    >
    </button>
  </div>
</ng-container>
<ng-template #processing>
  <p class="text-center">{{message || ('auth.registration.confirmation.processing' | translate)}}</p>
</ng-template>
