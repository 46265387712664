import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {CurrentUserViewModel} from '../../../../core/models/current-user.view-model';
import {UserApiModel} from '../../../../api/models/user.api-model';
import {UserViewModel} from '../../../../features/user/settings/user.view-model';
import {Router} from '@angular/router';
import {ModalService} from '../../modal.service';
import {FollowApiModel} from '../../../../api/models/follow.api-model';
import {
  SocialDataProviderService
} from '../../../timeline/social-grid-list/social-data-provider.service';
import {UserRestService} from '../../../../api/services/user.rest.service';
import {QueryParamsApiModel} from '../../../../api/models/query-params-api.model';
import {concatMap, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-item-action-dial',
  templateUrl: './item-action-dial.component.html',
  styleUrls: ['./item-action-dial.component.scss']
})
export class ItemActionDialComponent implements OnInit {
  @Input() public isFollowedByCurrentUser = false;
  @Input() public isReactedByCurrentUser = false;
  @Input() public currentUserVM: CurrentUserViewModel;
  @Input() public subjectUserVM: any;
  public socialMenuItems: MenuItem[] = [];
  public visibleList = false;
  constructor(
    private readonly router: Router,
    private readonly ms: ModalService,
    private readonly socialDPS: SocialDataProviderService,
    private readonly userRS: UserRestService
  ) { }

  ngOnInit(): void {
    this.initSocialMenuItems();
    if (this.subjectUserVM) {
      this.subjectUserVM = new UserViewModel(this.subjectUserVM.apiModel);
      this.subjectUserVM.receivedFollows = [];
      this.subjectUserVM.receivedReactions = [];
    }
  }

  public initSocialMenuItems(): void {
    this.socialMenuItems = [
      {
        icon: 'pi pi-user',
        command: () => {
          this.router.navigate(['/', this.subjectUserVM.slug, 'profile']).then(() => this.ms.close());
        }
      },
      {
        icon: 'pi pi-warehouse',
        command: () => {
          this.router.navigate(['/', this.subjectUserVM.slug, 'garage']).then(() => this.ms.close());
        }
      },
      {
        icon: 'pi pi-eye',
        styleClass: this.isFollowedByCurrentUser ? 'active' : '',
        command: () => {
          this.visibleList = true;
          this.toggleFollow();
        },
        disabled: this.subjectUserVM['@id'] === this.currentUserVM['@id'],
        visible: this.subjectUserVM['@id'] !== this.currentUserVM['@id']
      },
      {
        icon: 'pi pi-thumbs-up-fill',
        styleClass: this.isReactedByCurrentUser ? 'active' : '',
        command: () => {
          this.visibleList = true;
          this.toggleReaction();
        },
        disabled: this.subjectUserVM['@id'] === this.currentUserVM['@id'],
        visible: this.subjectUserVM['@id'] !== this.currentUserVM['@id']
      },
      // {
      //   // label: 'Nie interesuje mnie',
      //   icon: 'pi pi-times',
      //   command: () => {
      //     // this.removeFollowAndReaction();
      //   }
      // }
    ];
  }

  public toggleFollow(): void {
    if (!this.isFollowedByCurrentUser) {
      this.postFollow();
    } else {
      this.removeFollow();
    }
  }

  public postFollow(): void {
    this.socialDPS.postFollow(this.subjectUserVM).subscribe(() => {
      this.isFollowedByCurrentUser = true;
      this.initSocialMenuItems();
    });
  }

  public removeFollow(): void {
    this.socialDPS.getAllUserFollowsCollection(this.subjectUserVM).pipe(concatMap(() => {
      const followAM: FollowApiModel = this.subjectUserVM.receivedFollows.find(e => e.followedBy === this.currentUserVM['@id'] || e.followedBy['@id'] === this.currentUserVM['@id']);
      return this.socialDPS.removeFollow(followAM, this.subjectUserVM);
    })).subscribe(() => {
      this.isFollowedByCurrentUser = false;
      this.initSocialMenuItems();
    });
  }

  public toggleReaction(): void {
    if (!this.isReactedByCurrentUser) {
      this.postReaction();
    } else {
      this.removeReaction();
    }
  }

  public postReaction(): void {
    this.socialDPS.postReaction(this.subjectUserVM).subscribe(() => {
      this.isReactedByCurrentUser = true;
      this.initSocialMenuItems();
    });
  }

  public removeReaction(): void {
    this.socialDPS.getAllUserLikesCollection(this.subjectUserVM).pipe(concatMap(() => {
      const reactionAM = this.subjectUserVM.receivedReactions.find(e => e.author === this.currentUserVM['@id'] || e.author['@id'] === this.currentUserVM['@id']);
      return this.socialDPS.removeReaction(reactionAM, this.subjectUserVM);
    })).subscribe(() => {
      this.isReactedByCurrentUser = false;
      this.initSocialMenuItems();
    });
  }
}
