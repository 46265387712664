import {Exclude, Expose} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';

@Exclude()
export class SavedAdvertisementSearchApiModel extends AbstractApiModel {
  @Expose() public id: number;
  @Expose() public user: string;
  @Expose() public notifications: boolean;
  @Expose() public lastResultsCount: number;
  @Expose() public address: object;
  @Expose() public filters: object;
  @Expose() public readableFilters: { property: string; label: any, type: string, appendix?: string }[];
  @Expose() public updatedAt: Date;
  @Expose() public createdAt: Date;
  public isPlaceholder: boolean = false;
}
