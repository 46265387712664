import { Injectable } from '@angular/core';
import {ResultListApiModel} from "../../api/models/result-list.api.model";
import {MarketplaceAdvertisementApiModel} from "../../api/models/marketplace-advertisement.api.model";
import {UserViewModel} from "../../features/user/settings/user.view-model";
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {MarketplaceAdvertisementRestService} from "../../api/services/marketplace-advertisement.rest.service";
import {UserRestService} from "../../api/services/user.rest.service";
import {mergeMap, Observable, of} from "rxjs";
import {FollowApiModel} from "../../api/models/follow.api-model";
import {FilterParameterApiModel} from "../../api/models/filter-parameter.api.model";
import {map, tap} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {HttpResponse} from "@angular/common/http";
import {FollowerRestService} from "../../api/services/follower.rest.service";

@Injectable({
  providedIn: 'root'
})
export class OffersDataProviderService {

  public savedOffers: MarketplaceAdvertisementApiModel[] = [];
  public totalItems: number = 0;
  constructor(
    private readonly marketplaceAdvertisementRS: MarketplaceAdvertisementRestService,
    private readonly userRS: UserRestService,
    private readonly followerRS: FollowerRestService
  ) { }


  public getCollection(queryParams: QueryParamsApiModel = new QueryParamsApiModel(), onlySaved: boolean, owner?: UserViewModel): Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>> {
    let res: Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>>;
    if (owner && onlySaved) {
      res = this.getSavedMarketplaceAdvertisements(queryParams, owner);
    } else if (owner) {
      res = this.userRS.getMarketplaceAdvertisementsSubResourceCollection(owner['@id'], queryParams);
    } else {
      res = this.marketplaceAdvertisementRS.getCollection(queryParams)
    }

    return res;
  }

  public getSavedMarketplaceAdvertisements(queryParams: QueryParamsApiModel = new QueryParamsApiModel(), owner: UserViewModel): Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>> {
    return this.userRS.getSavedMarketplaceAdvertisementsSubResourceCollection(owner['@id'], queryParams).pipe(mergeMap((resultListAM: ResultListApiModel<FollowApiModel>) => {
      resultListAM.records.forEach((item: FollowApiModel) => {
        const slug = (item.followed as unknown as string).replace('/api/marketplace_advertisements/', '');
        queryParams.filters.push(new FilterParameterApiModel('slug', slug, 'array'));
      });

      if (!queryParams.filters.length) {
        return of(new ResultListApiModel<MarketplaceAdvertisementApiModel>(MarketplaceAdvertisementApiModel));
      }

      return this.marketplaceAdvertisementRS.getCollection(queryParams).pipe(map((marketplaceAdvertisementResultListAM) => {
        marketplaceAdvertisementResultListAM.records.forEach((offer) => {
          offer.currentUserFollowing = resultListAM.records.find(item => item.followed as unknown as string === offer['@id']);
        })
        return marketplaceAdvertisementResultListAM;
      }));
    }));
  }

  public postFollow(offer: MarketplaceAdvertisementApiModel): void {
    this.followerRS.create(plainToClass(FollowApiModel, {followed: offer['@id'], type: 'MARKETPLACE_ADVERTISEMENT'}))
      .pipe(map((followAM: FollowApiModel) => {
        offer.currentUserFollowing = followAM;
        this.savedOffers = [...this.savedOffers, offer];
        return followAM;
      })).subscribe();
  }

  public removeFollow(savedOffer: MarketplaceAdvertisementApiModel): void {
    const followAM = savedOffer.currentUserFollowing;
    this.followerRS.delete(savedOffer.currentUserFollowing).pipe(map((e: HttpResponse<any>) => {
      savedOffer.currentUserFollowing = null;
      this.savedOffers = this.savedOffers.filter((x: MarketplaceAdvertisementApiModel) => x['@id'] !== (followAM.followed['@id'] || followAM.followed));
      return null;
    })).subscribe();
  }

  public toggleFollow(offer: Record<string, any>, $event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    const savedOffer = this.savedOffers.find( e => e['@id'] === offer['@id']);
    if (!savedOffer) {
      this.postFollow(offer as MarketplaceAdvertisementApiModel);
    } else {
      this.removeFollow(savedOffer);
    }
  }
}
