<form [formGroup]="registerForm" (ngSubmit)="register()" novalidate>
  <h1 class="text-center m-1">
    <img src="assets/layout/images/wtg-logo-transparent-white.webp" alt="wtg watch this garage logo" class="app-logo">
    <small class="-mt-3" style="color: #4285F4">{{ 'auth.registration.title' | translate }}</small>
  </h1>
  <div class="col-12 p-fluid">
    <div class="p-float-label p-input-icon-left">
      <i class="pi pi-user"></i>
      <input pInputText minLength="6" maxLength=20 data-error=" " data-success=" " type="text" id="username"
             class="form-control" formControlName="username">
      <label for="username">{{ 'auth.registration.username' | translate }}</label>
    </div>
    <div class="app-form-error"
         *ngIf="registerForm.controls['username'].invalid && registerForm.controls['username'].touched">
      <p
        *ngIf="registerForm.controls['username'].errors.required">{{ 'auth.registration.validation.required_name' | translate }}</p>
      <p
        *ngIf="registerForm.controls['username'].errors.minlength">{{ 'auth.registration.validation.min' | translate }}</p>
      <p
        *ngIf="registerForm.controls['username'].errors.maxlength">{{ 'auth.registration.validation.max' | translate }}</p>
      <p
        *ngIf="registerForm.controls['username'].errors.custom">{{ registerForm.controls['username'].errors.custom }}</p>
    </div>
  </div>

  <div class="col-12 p-fluid">
    <div class="p-float-label p-input-icon-left">
      <i class="pi pi-envelope"></i>
      <input pInputText data-error=" " data-success=" " type="email" id="email" class="form-control"
             formControlName="email">
      <label for="email">{{ 'auth.registration.email' | translate }}</label>
    </div>
    <div class="app-form-error"
         *ngIf="registerForm.controls['email'].invalid && registerForm.controls['email'].touched">
      <p
        *ngIf="registerForm.controls['email'].errors.required">{{ 'auth.registration.validation.required_email' | translate }}</p>
      <p *ngIf="registerForm.controls['email'].errors.email">{{ 'auth.registration.validation.email' | translate }}</p>
      <p *ngIf="registerForm.controls['email'].errors.custom">{{ registerForm.controls['email'].errors.custom }}</p>
    </div>
  </div>

  <div formGroupName="password">
    <div class="col-12 p-fluid">
      <div class="p-float-label p-input-icon-left">
        <i class="pi pi-key"></i>
        <input pInputText minLength="6" maxLength="50" data-error=" " data-success=" " type="password"
               id="password"
               class="form-control" formControlName="first" autocomplete="new-password">
        <label for="password">{{ 'auth.registration.password' | translate }}</label>
      </div>
      <div class="app-form-error"
           *ngIf="registerForm.controls['password'].invalid && registerForm.controls['password'].touched">
        <p *ngIf="registerForm.controls['password']['controls']['first'].errors?.required">
          {{ 'auth.registration.validation.password_required' | translate }}</p>
        <p *ngIf="registerForm.controls['password']['controls']['first'].errors?.minlength">
          {{ 'auth.registration.validation.password_min' | translate }}</p>
        <p *ngIf="registerForm.controls['password']['controls']['first'].errors?.maxlength">
          {{ 'auth.registration.validation.password_max' | translate }}</p>
        <p *ngIf="registerForm.controls['password']['controls']['second'].errors?.required">
          {{ 'auth.registration.validation.password_retyped' | translate }}</p>
        <p *ngIf="registerForm.controls['password']['controls']['first'].errors?.custom">
          {{ registerForm.controls['password']['controls']['first'].errors['custom'] }}</p>
      </div>
    </div>

    <div class="col-12 p-fluid">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-replay prefix"></i>
              <input pInputText minLength="6" maxLength="50" data-error=" " data-success=" " type="password"
                     id="retyped-password" class="form-control" formControlName="second" autocomplete="new-password">
              <label for="retyped-password">{{ 'auth.registration.validation.password_retype' | translate }}</label>
            </span>
      <div class="app-form-error"
           *ngIf="registerForm.controls['password'].invalid && registerForm.controls['password']['controls']['second'].touched">
        <p *ngIf="registerForm.controls['password']['controls']['second'].errors?.matchPassword">
          {{ 'auth.registration.validation.password_match' | translate }}
        </p>
        <p
          *ngIf="registerForm.controls['password']['controls']['second'].errors?.custom">{{ registerForm.controls['password']['controls']['second'].errors.custom }}</p>
      </div>
    </div>

  </div>
  <div class="col-12 p-fluid">
    <p-checkbox [binary]="true" [formControl]="registerForm.controls['terms']"
                [label]="'auth.registration.accept_terms' | translate">
    </p-checkbox>
    <a class="ml-2 mr-3 privacy-no-auth" [routerLink]="['/privacy-no-auth']"
       target="_blank">{{ 'features.privacy.privacy' | translate }}</a>
    <div class="app-form-error"
         *ngIf="registerForm.controls['terms'].invalid && registerForm.controls['terms'].touched">
      <p
        *ngIf="registerForm.controls['terms'].errors.required">{{ 'auth.registration.validation.accept_terms' | translate }}</p>
      <p *ngIf="registerForm.controls['terms'].errors.custom">{{ registerForm.controls['terms'].errors.custom }}</p>
    </div>
  </div>

  <div class="col-12 p-fluid">
    <button pButton pRipple
            type="submit"
            icon="pi pi-plus"
            iconPos="left"
            class="p-button-primary p-button-outlined p-button-rounded"
            [disabled]="registerForm.invalid"
            [label]="'auth.registration.button.register' | translate">
    </button>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            type="button"
            icon="pi pi-arrow-left"
            iconPos="left"
            class="p-button-danger p-button-outlined p-button-rounded"
            [routerLink]="['/login']"
            [label]="'common.button.back' | translate">
    </button>
  </div>
</form>
