import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {FileUpload, FileUploadModule} from "primeng/fileupload";
import {FilesUploadEventInterface} from "../../interfaces/files-upload-event.interface";
import {FileRemoveEventInterface} from "../../interfaces/file-remove-event.interface";
import {MediaObjectApiModel} from "../../../api/models/media-object.api-model";
import {forkJoin, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {MediaObjectRestService} from "../../../api/services/media-object.rest.service";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {ModalService} from "../../modal/modal.service";
import {CurrentUserViewModel} from "../../../core/models/current-user.view-model";
import {ProjectViewModel} from "../../../features/user/garage/project.view-model";
import {ModalStateEvent} from "../../modal/modal-state-event";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from "primeng/dialog";
import {MediaModule} from "../media.module";
import {ViewTypeEnum} from "../../../core/enums/view-type.enum";
import {MediaChoiceComponent} from "../../modal/components/media-choice/media-choice.component";
import {FileRestService} from "../../../api/services/file.rest.service";
import {MediaObjectPipe} from "../../../core/pipes/media-object.pipe";
import {MediaObjectInterface} from "../../../core/interfaces/media-object.interface";
import {MediaObjectViewModel} from "../../../core/models/media-object.view-model";

@Component({
  selector: 'app-media-uploader',
  standalone: true,
  imports: [
    FileUploadModule,
    TranslateModule,
    NgIf,
    Ripple,
    ConfirmDialogModule,
    DialogModule,
    MediaModule,
    MediaChoiceComponent
  ],
  templateUrl: './media-uploader.component.html',
  styleUrl: './media-uploader.component.scss'
})
export class MediaUploaderComponent {

  public image: any;
  public objectURL = '';
  public files: File[] = [];
  public newFiles: File[] = [];
  public disabled = false;
  public mediaProgress: number | null = null;
  public mediaChoiceOpened = false;
  @Input() public mediaObjectAMs: MediaObjectInterface[] = [];
  public mediaDialogOpened: boolean = false;
  @Input() public currentUserVM: CurrentUserViewModel | null = null;
  @Input() public projectVM: ProjectViewModel | null = null;
  public readonly window = window;
  @ViewChild(MediaChoiceComponent, {static: false}) mediaChoice!: MediaChoiceComponent;
  @ViewChild(FileUpload) fileUploader: FileUpload;

  constructor(
    private readonly renderer: Renderer2,
    private readonly mediaObjectRS: MediaObjectRestService,
    private readonly ms: ModalService,
    private readonly frs: FileRestService,
  ) {
  }

/*  ngAfterContentInit(): void {
    this.convertUrlToFile(this.mediaObjectAMs.map(e => e.hyperlinks[0].uri));

    this.selectMedia(this.fileUploader);
  }*/

  onUpload(event: any, type: 'single' | 'multiple') {
    console.log(event);
    this.openMediaBox();
    switch (type) {
      case 'single':
        const file = event.files[0];
        file.objectURL = file.objectURL ? file.objectURL : this.objectURL;
        if (!file.objectURL) {
          return;
        } else {
          this.image = file;
          this.objectURL = file.objectURL;
        }
        break;
      case 'multiple':
        // this.form.get('images').setValue(event.files);
        break;
    }
  }

  public openMediaBox(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-fileupload-row button');
      elements.forEach((e) => {
        this.renderer.addClass(e, 'p-button-text');
      });
    }, 1);
  }

  public onFileSelect(event: FilesUploadEventInterface): void {
    event.files.forEach((file: File) => {
      if (!this.files.includes(file)) {
        this.files.push(file);
        this.newFiles.push(file);
      }
    });
  }

  onFileRemove(event: FileRemoveEventInterface): void {
    this.removeMedia(event).subscribe((mediaObjectAM: MediaObjectApiModel) => {
      if (mediaObjectAM) {
        this.mediaObjectAMs = this.mediaObjectAMs.filter(e => e['@id'] !== mediaObjectAM['@id']);
        if (this.mediaChoice) {
          this.mediaChoice.selectedMedia = this.mediaChoice.selectedMedia.filter(e => e['@id'] !== mediaObjectAM['@id']);
        }
      }
      console.log(this.mediaObjectAMs, mediaObjectAM);
      this.files = this.files.filter(e => e.name !== event.file.name);
      this.newFiles = this.newFiles.filter(e => e.name !== event.file.name);
      this.closeMediaLoadProcess();
      this.disabled = false;
    });
  }

  removeMedia(event: FileRemoveEventInterface): Observable<any> {
    this.disabled = true;
    console.log(event.file.name, this.mediaObjectAMs);
    const mediaObjectAM = this.mediaObjectAMs.find(
      (mediaObjectAM: MediaObjectInterface) => mediaObjectAM.name === event.file.name || mediaObjectAM.originalName === event.file.name
    );
    if (mediaObjectAM) {
      return of(mediaObjectAM);
    }
    return of(null);
  }

  public deleteMediaObject(mediaObjectAM: MediaObjectApiModel): Observable<MediaObjectApiModel> {
    return this.mediaObjectRS.deleteMediaObject(mediaObjectAM['@id']).pipe(map(() => mediaObjectAM));
  }

  public closeMediaLoadProcess(): void {
    this.mediaProgress = null;
  }

  public openMediaChoiceModal(): void {
    if (this.currentUserVM) {
      this.mediaDialogOpened = true;
      // this.ms.showMediaChoiceModal({currentUserVM: this.currentUserVM, projectVM: this.projectVM}).successState.subscribe((state: ModalStateEvent) => {
      //   console.log(state)
      // });
    }
  }

  selectMedia(fileUploader: FileUpload): void {
    if (this.mediaChoice) {
      this.mediaObjectAMs = this.mediaChoice.selectedMedia;
    }
    this.files = this.files.filter(f => !![...this.mediaObjectAMs, ...this.newFiles].find(m => m.name === f.name));
    this.convertUrlToFile(this.mediaObjectAMs.map(e => MediaObjectPipe.transformToLink(e)))
      .subscribe(() => {
        fileUploader.files = this.files;
        console.log(this.files);
        fileUploader.upload();
        this.openMediaBox();
        this.mediaDialogOpened = false;
      });

  }

  convertUrlToFile(fileUrls: string[]): Observable<File[]> {
    console.log(fileUrls);
    const requests: Observable<File>[] = [];
    for (const url of fileUrls) {
      // arr.push(new File([await (await fetch(url)).blob()], url, {type: 'image/jpeg'}));
      requests.push(this.frs.getImage(url).pipe(tap(file => {
        if (!this.files.find(f => f.name == file.name)) {
          this.files.push(file);
        }
      })));
    }

    if (!requests.length) {
      this.files = [...this.newFiles];
      return of(this.files);
    }

    return forkJoin(requests);
  }


  private getUniqueFiles(files: File[]): File[] {
    const uniqueFilesSet = new Set<string>();
    const uniqueFiles: File[] = [];

    files.forEach(file => {
      const fileKey = `${file.name}-${file.size}-${file.lastModified}`;
      if (!uniqueFilesSet.has(fileKey)) {
        uniqueFilesSet.add(fileKey);
        uniqueFiles.push(file);
      }
    });

    return uniqueFiles;
  }

  public reset(): void {
    this.mediaChoice.reset();
  }

  public selectAll(): void {
    this.mediaChoice.selectAll();
  }
}
