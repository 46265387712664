<h1 class="text-sm mb-5 text-color-secondary">
  {{ 'shared.modal.component.add_vehicle.choose_vehicle'|translate|uppercase }}
</h1>
<form [formGroup]="vehicleForm" novalidate>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="vehicleType"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.vehicle_type' | translate"
            [options]="vehicleTypes"
            (onChange)="seekMakes(vType.value)"
            #vType
        >
        </p-dropdown>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="make"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.make' | translate"
            [options]="makes"
            (onChange)="seekBodyTypes(vType.value, make.value)"
            #make
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="grid ">
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="bodyType"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.body_type' | translate"
            [options]="bodyTypes"
            (onChange)="seekModels(vType.value, make.value, bType.value)"
            #bType
        >
        </p-dropdown>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="model"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.model' | translate"
            [options]="models"
            (onChange)="seekBodyGens(vType.value, make.value, bType.value, model.value)"
            #model
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="bodyGen"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.body_gen' | translate"
            [options]="bodyGens"
            (onChange)="seekEditions(vType.value, make.value, bType.value, model.value, bGen.value)"
            #bGen
        >
        </p-dropdown>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-dropdown
            formControlName="edition"
            filter="true"
            dataKey="@id"
            optionLabel="name"
            appendTo="body"
            [autofocus]="false"
            [autofocusFilter]="false"
            [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.edition' | translate"
            [options]="editions"
            (onChange)="seekVehicle()"
            #edition
        >
        </p-dropdown>
      </div>
    </div>
  </div>
<!--  <div class="grid">-->
<!--    <div class="col-12 p-fluid">-->
<!--      <p *ngIf="vType.selectedOption">{{'shared.modal.component.add_vehicle.choice' | translate}}</p>-->
<!--      <p class="font-weight-bold">-->
<!--        {{vType?.label}} {{make?.label}}-->
<!--        {{bType?.label}} {{model?.label}}-->
<!--        {{bGen?.label}} {{edition?.label}}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
</form>
