import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {PersonalInformationRestService} from './services/personal-information.rest.service';
import {AuthenticationRestService} from './services/authentication.rest.service';
import {RegistrationRestService} from './services/registration.rest.service';
import {MediaObjectRestService} from './services/media-object.rest.service';
import {FollowerRestService} from './services/follower.rest.service';
import {ProjectRestService} from './services/project.rest.service';
import {PostRestService} from './services/post-rest.service';
import {UserRestService} from './services/user.rest.service';
import {VehicleRestService} from './services/vehicle.rest.service';
import {VehicleTypeRestService} from './services/vehicle-type.rest.service';
import {MakeRestService} from './services/make.rest.service';
import {BodyTypeRestService} from './services/body-type.rest.service';
import {ModelRestService} from './services/model.rest.service';
import {BodyGenRestService} from './services/body-gen.rest.service';
import {EditionRestService} from './services/edition.rest.service';
import {HashtagRestService} from './services/hashtag.rest.service';
import {RatingRestService} from './services/rating.rest.service';
import {GoalRestService} from './services/goal.rest.service';
import {SearchLogRestService} from './services/search-log.rest.service';
import {NotificationRestService} from './services/notification.rest.service';
import {FileRestService} from './services/file.rest.service';
import {ReactionRestService} from './services/reaction.rest.service';
import {provideClientHydration} from "@angular/platform-browser";
import {provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {EngineRestService} from "./services/engine.rest.service";
import {TransmissionRestService} from "./services/transmission.rest.rervice";
import {WheelDriveRestService} from "./services/wheel-drive.rest.service";
import {EquipmentRestService} from "./services/equipment.rest.service";
import {EquipmentCategoryRestService} from "./services/equipment-category.rest.service";
import {MarketplaceAdvertisementRestService} from "./services/marketplace-advertisement.rest.service";
import {SavedAdvertisementSearchRestService} from "./services/saved-advertisement-search.rest.service";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    provideClientHydration(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    AuthenticationRestService,
    FollowerRestService,
    MediaObjectRestService,
    PersonalInformationRestService,
    ProjectRestService,
    RegistrationRestService,
    PostRestService,
    UserRestService,
    VehicleRestService,
    SearchLogRestService,
    AuthenticationRestService,
    FollowerRestService,
    MediaObjectRestService,
    PersonalInformationRestService,
    ProjectRestService,
    RegistrationRestService,
    PostRestService,
    UserRestService,
    VehicleRestService,
    VehicleTypeRestService,
    MakeRestService,
    BodyTypeRestService,
    ModelRestService,
    BodyGenRestService,
    EditionRestService,
    HashtagRestService,
    RatingRestService,
    GoalRestService,
    NotificationRestService,
    FileRestService,
    ReactionRestService,
    FileRestService,
    EngineRestService,
    TransmissionRestService,
    WheelDriveRestService,
    EquipmentRestService,
    EquipmentCategoryRestService,
    MarketplaceAdvertisementRestService,
    SavedAdvertisementSearchRestService
  ]
})
export class ApiModule {
}
