import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ITEM_TYPE, OnRequestAppend} from '@egjs/infinitegrid';
import {mergeMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryService} from '../../../core/services/query.service';
import {QueryResourceInterface} from '../../../core/models/query-resource.interface';
import {SearchTabsEnum} from '../../../core/components/search-results-list/search-tabs.enum';
import {AppBreadcrumbService} from '../../navbar/app.breadcrumb.service';
import {NgxMasonryInfiniteGridComponent} from '@egjs/ngx-infinitegrid';
import {ResultsListTabInterface} from '../../../core/components/search-results-list/results-list-tab.interface';
import {Subscription} from "rxjs";
import {ModalService} from "../../modal/modal.service";

@Component({
  selector: 'app-search-infinite-list',
  templateUrl: './search-infinite-list.component.html',
  styleUrls: ['./search-infinite-list.component.scss']
})
export class SearchInfiniteListComponent implements OnInit, AfterViewInit, OnDestroy {
  get selectedTab(): ResultsListTabInterface {
    return this._selectedTab;
  }

  @Input() set selectedTab(value: ResultsListTabInterface) {
    this._selectedTab = value;
    this.setItemsByType(this._selectedTab.type);
  }

  constructor(
    public readonly queryService: QueryService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly breadcrumb: AppBreadcrumbService
  ) { }

  public readonly ITEM_TYPE = ITEM_TYPE;
  public page = 2;
  public totalItems: {[key: string]: number} = {projects: 0, users: 0, events: 0, marketplace: 0};
  public items: QueryResourceInterface[] = [];
  public query: any;
  private _selectedTab: ResultsListTabInterface;
  @Input() public noResultsMsg: string;
  @ViewChild('ig') public ig: NgxMasonryInfiniteGridComponent;
  public endOfResults = false;
  public loading: boolean = true;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.route.queryParams.subscribe(({q}: {q: string}) => {
      this.query = q;
      this.initList();
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.items = [];
    this.page = 2;
    this.queryService.resetCachedResults();
    this.endOfResults = false;
    delete this.ig;
    this.subscriptions.forEach(e => e.unsubscribe());
    console.log(this, 'destroy');
  }

  initList(): void {
    this.breadcrumb.filtersVisible = this.selectedTab.filtersAvailable;
    this.queryService.resetCachedResults();
    this.getPlaceHolders();
    this.getCollection(1);
  }

  onRequestAppend(event: OnRequestAppend): void {
    this.setItemsByType(this.selectedTab.type);
    if (this.endOfResults || (this.totalItems[this.selectedTab.type] && this.items.length >= this.totalItems[this.selectedTab.type])) {
      return;
    }

    event.wait();

    const sub = this.queryService.fetchResources(this.query, this.page)
      .pipe(tap(() => this.totalItems = this.queryService.totalItems))
      .subscribe(() => {
        this.setItemsByType(this.selectedTab.type);

        if (!this.items.length) {
          this.endOfResults = true;
        }

        const oldItems = this.items.filter(e => e.isPlaceholder === false);
        // console.log(oldItems, items);
        // this.items = [...oldItems, ...items];

        this.page++;
        this.ig?.updateItems();

        event.ready();
    });

    this.subscriptions.push(sub);
  }

  private getPlaceHolders(): void {
    // this.loading = true;
    // this.items = [
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    //   {isPlaceholder: true, thumbnail: ''},
    // ];
  }

  public getDefaultThumbnailByType(type: string): 'background'|'car'|'avatar' {
    switch (type) {
      case 'events': return  'background';
      case 'projects': return  'car';
      case 'users': return  'avatar';
      default: return 'background';
    }
  }

  private getCollection(page: number) {
    const sub = this.queryService.fetchResources(this.query, page)
      .pipe(tap(() => this.totalItems = this.queryService.totalItems))
      .subscribe(() => {
        this.setItemsByType(this.selectedTab.type);
        this.loading = false;
        if (this.ig) {
        //   console.log(this.ig);
          this.ig.renderItems();
        }
      });
    this.subscriptions.push(sub);
  }

  private  setItemsByType(type: string): void {
    switch (type) {
      case SearchTabsEnum.ALL: this.items = this.queryService.resources; break;
      case SearchTabsEnum.EVENTS:  this.items = this.queryService.events; break;
      case SearchTabsEnum.PROJECTS: this.items = this.queryService.projects; break;
      case SearchTabsEnum.USERS: this.items = this.queryService.users; break;
      case SearchTabsEnum.MARKETPLACE: this.items = this.queryService.marketplace; break;
      default: this.items = this.queryService.resources;
    }
  }
}
