import {ChangeDetectorRef, Injectable} from '@angular/core';
import {VehicleRestService} from "../../api/services/vehicle.rest.service";
import {VehicleTypeRestService} from "../../api/services/vehicle-type.rest.service";
import {forkJoin, mergeMap, Observable, of, Subject, Subscription} from "rxjs";
import {VehicleTypeViewModel} from "../../core/models/vehicle-type.view-model";
import {VehicleTypeApiModel} from "../../api/models/vehicle-type.api-model";
import {map, tap} from "rxjs/operators";
import {ResultListApiModel} from "../../api/models/result-list.api.model";
import {MakeViewModel} from "../../core/models/make.view-model";
import {MakeRestService} from "../../api/services/make.rest.service";
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {FilterParameterApiModel} from "../../api/models/filter-parameter.api.model";
import {BodyTypeRestService} from "../../api/services/body-type.rest.service";
import {BodyTypeViewModel} from "../../core/models/body-type.view-model";
import {BodyTypeApiModel} from "../../api/models/body-type.api-model";
import {ModelRestService} from "../../api/services/model.rest.service";
import {ModelApiModel} from "../../api/models/model.api-model";
import {ModelViewModel} from "../../core/models/model.view-model";
import {BodyGenViewModel} from "../../core/models/body-gen.view-model";
import {BodyGenApiModel} from "../../api/models/body-gen.api-model";
import {BodyGenRestService} from "../../api/services/body-gen.rest.service";
import {EditionRestService} from "../../api/services/edition.rest.service";
import {EditionViewModel} from "../../core/models/edition.view-model";
import {EditionApiModel} from "../../api/models/edition.api-model";
import {VehicleViewModel} from "../../features/user/garage/vehicle.view-model";
import {VehicleInterface} from "../modal/components/add-vehicle/vehicle.interface";
import {VehicleApiModel} from "../../api/models/vehicle.api-model";
import {AbstractDictionaryViewModel} from "../../core/models/abstract-dictionary.view-model";
import {AbstractDictionaryApiModel} from "../../api/models/abstract-dictionary.api-model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {HttpParams} from "@angular/common/http";
import {EquipmentCategoryApiModel} from "../../api/models/equipment-category.api-model";
import {EquipmentCategoryRestService} from "../../api/services/equipment-category.rest.service";
import {SelectItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {getTranslatableColorOptions} from "../../core/enums/color.enum";
import {MakeApiModel} from "../../api/models/make.api.model";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {SavedAdvertisementSearchRestService} from "../../api/services/saved-advertisement-search.rest.service";
import {plainToClass} from "class-transformer";
import {SavedAdvertisementSearchApiModel} from "../../api/models/saved-advertisement-search.api-model";
import {CurrentUserViewModel} from "../../core/models/current-user.view-model";
import {Button} from "primeng/button";
import {ModalStateEvent} from "../modal/modal-state-event";
import {ModalState} from "../modal/modal-state.enum";
import {ModalService} from "../modal/modal.service";
import {OffersDataProviderService} from "../marketplace/offers.data-provider.service";
import {MarketplaceAdvertisementApiModel} from "../../api/models/marketplace-advertisement.api.model";
import {Params} from "@angular/router";
import {RxFormBuilder} from "@rxweb/reactive-form-validators";
import {StringHelper} from "../../core/helpers/string.helper";

@Injectable({
  providedIn: 'root'
})
export class FiltersDataProviderService {

  public vehicleTypes: VehicleTypeViewModel[] = [];
  public makes: MakeViewModel[] = [];
  public bodyTypes: BodyTypeViewModel[] = [];
  public models: ModelViewModel[] = [];
  public form: UntypedFormGroup;
  public filtersSnapshot: object = {};
  public queryParams: QueryParamsApiModel = new QueryParamsApiModel();
  public fuelTypes: SelectItem[] = [];
  public equipmentCategoryCollection: EquipmentCategoryApiModel[] = [];
  public filtersPrefetched: boolean = false;
  public readonly colours: SelectItem[] = getTranslatableColorOptions();
  public subscription: Subscription;
  public subscriptions: Subscription[] = [];
  public subject: Subject<ModalStateEvent> = new Subject<ModalStateEvent>();

  constructor(
    private readonly fb: RxFormBuilder,
    private vehicleTypeRS: VehicleTypeRestService,
    private makeRS: MakeRestService,
    private bodyTypeRS: BodyTypeRestService,
    private modelRS: ModelRestService,
    private bodyGenRS: BodyGenRestService,
    private editionRS: EditionRestService,
    private vehicleRS: VehicleRestService,
    private params: QueryParamsService,
    private readonly equipmentCategoryRS: EquipmentCategoryRestService,
    private readonly saveAdvertisementSearchRS: SavedAdvertisementSearchRestService,
    private readonly translate: TranslateService,
    private readonly offerDPS: OffersDataProviderService,
    private readonly ms: ModalService
  ) {
    this.fuelTypes = [
      { label: this.translate.instant('common.gasoline'), value: ['Gasoline', 'petrol', 'Petrol', 'Gas', 'Gasoline, Gas', 'Rotor'] },
      { label: this.translate.instant('common.diesel'), value: ['Diesel', 'diesel', 'Diesel, Hybrid'] },
      { label: this.translate.instant('common.electric'), value: ['Electric'] },
      { label: this.translate.instant('common.hybrid'), value: ['Hybrid', 'hybrid', 'Diesel, Hybrid', 'Gasoline, Electric'] },
      { label: this.translate.instant('common.lpg'), value: ['Liquefied coal hydrogen gases'] }
    ];
  }

  public seekMakes(vehicleTypeVMs: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[]): Observable<MakeViewModel[]> {
    this.subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('project.vehicle.make', ['project.vehicle.make', 'project.vehicle.model']);
    this.unsubscribePending();
    return this.getMakeCollection(vehicleTypeVMs).pipe(tap(() => {
      this.form.controls['project.vehicle.make'].enable();
      this.subject.next(new ModalStateEvent(ModalState.PROCESS_END));
    }));
  }

  public seekModels(vehicleTypeVM: VehicleTypeViewModel[], makeVM: MakeViewModel[]): Observable<ModelViewModel[]> {
    this.subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('project.vehicle.model', ['project.vehicle.model']);
    this.unsubscribePending();
    return this.getModelCollection([vehicleTypeVM, makeVM]).pipe(tap(() => {
      this.form.controls['project.vehicle.model'].enable();
      this.subject.next(new ModalStateEvent(ModalState.PROCESS_END));
    }));
  }

  public getVehicleTypeCollection(): Observable<VehicleTypeViewModel[]> {
    return this.vehicleTypeRS.getVehicleTypeCollection()
      .pipe(map((vehicleTypeAMs: ResultListApiModel<VehicleTypeApiModel>) => {
        return vehicleTypeAMs.records.map((vehicleTypeAM: VehicleTypeApiModel) => new VehicleTypeViewModel(vehicleTypeAM))
      }))
  }

  public getMakeCollection(vehicleTypeVM: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[]): Observable<MakeViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    this.appendDictionaryFilters(queryParams, [vehicleTypeVM]);
    return this.makeRS.getMakeCollection(queryParams).pipe(map((makeAMs: ResultListApiModel<MakeApiModel>) => {
      this.makes  = makeAMs.records.map((makeAM: MakeApiModel) => new MakeViewModel(makeAM));
      return this.makes;
    }));
  }

  public getBodyTypeCollection(
    vms: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[][]
  ): Observable<BodyTypeViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    this.appendDictionaryFilters(queryParams, vms);
    return this.bodyTypeRS.getBodyTypeCollection(queryParams).pipe(map((bodyTypeAMs: ResultListApiModel<BodyTypeApiModel>) => {
      const bodyTypes = bodyTypeAMs.records.map((bodyTypeAM: BodyTypeApiModel) => new BodyTypeViewModel(bodyTypeAM));
      bodyTypes.forEach(e => e.name === '' ? e.name = 'Unknown' : null);
      return bodyTypes;
    }));
  }

  public getModelCollection(
    vms: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[][]
  ): Observable<ModelViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    this.appendDictionaryFilters(queryParams, vms);
    return this.modelRS.getModelCollection(queryParams).pipe(map((modelAMs: ResultListApiModel<ModelApiModel>) => {
      this.models = modelAMs.records.map((modelAM: ModelApiModel) => new ModelViewModel(modelAM));
      return this.models;
    }));
  }

  public getBodyGenCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
  ): Observable<BodyGenViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyType', bodyTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.model', modelVM["@id"], 'single'));
    return this.bodyGenRS.getBodyGenCollection(queryParams).pipe(map((bodyGenAMs: ResultListApiModel<BodyGenApiModel>) => {
      return bodyGenAMs.records.map((bodyGenAM: BodyGenApiModel) => new BodyGenViewModel(bodyGenAM));
    }));
  }

  public getEditionCollection(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
    bodyGenVM: BodyGenViewModel
  ): Observable<EditionViewModel[]> {
    const queryParams = new QueryParamsApiModel();
    queryParams.filters.push(new FilterParameterApiModel('vehicles.vehicleType', vehicleTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.make', makeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyType', bodyTypeVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.model', modelVM["@id"], 'single'));
    queryParams.filters.push(new FilterParameterApiModel('vehicles.bodyGen', bodyGenVM["@id"], 'single'));
    return this.editionRS.getEditionCollection(queryParams).pipe(map((bodyGenAMs: ResultListApiModel<EditionApiModel>) => {
      return bodyGenAMs.records.map((bodyGenAM: EditionApiModel) => new EditionViewModel(bodyGenAM));
    }));
  }

  public getVehicleByParams(vehicleForm: VehicleInterface): Observable<VehicleViewModel[]> {
    const keys = Object.keys(vehicleForm);
    const queryParams = new QueryParamsApiModel();
    for (let key of keys) {
      queryParams.filters.push(new FilterParameterApiModel(key, vehicleForm[key]['@id'], 'single'));
    }
    return this.vehicleRS.getVehicleCollection(queryParams)
      .pipe(map((vehicleAMs: ResultListApiModel<VehicleApiModel>) => {
         return vehicleAMs.records.map((vehicleAM: VehicleApiModel) => new VehicleViewModel(vehicleAM));
      }));
  }

  updateMarketplaceFilters(): Observable<any> {
    if (!this.filtersPrefetched) {
      this.filtersSnapshot = QueryParamsService.getNormalizedQueryParametersFromSessionStorage();
      return this.getAdvancedFiltersDependencies()
        .pipe(mergeMap(() => this.fetchAdditionalDependencies()), tap(() => {
          this.updateMarketplaceFiltersForm();
          this.filtersPrefetched = true;
        }))
    } else {
      this.updateMarketplaceFiltersForm();
      return of(null);
    }

  }

  fetchAdditionalDependencies(): any {
    const additionalDependencies: Observable<any>[] = [];
    const vehicleTypeArr = this.assignAbstractViewModelFromQueryParamsSnapshot('project.vehicle.vehicleType', VehicleTypeViewModel);
    additionalDependencies.push(vehicleTypeArr.length ? this.seekMakes(vehicleTypeArr) : of([]));
    const makeArr = this.assignAbstractViewModelFromQueryParamsSnapshot('project.vehicle.make', MakeViewModel);
    additionalDependencies.push(makeArr.length ? this.getModelCollection([vehicleTypeArr, makeArr]) : of([]));

    return forkJoin(additionalDependencies);
  }

  private assignAbstractViewModelFromQueryParamsSnapshot(key: string, T: any): AbstractDictionaryViewModel<AbstractDictionaryApiModel>[] {
    if (this.filtersSnapshot[key] && this.filtersSnapshot[key] instanceof Array) {
      return this.filtersSnapshot[key].map(id => {
        const model = new T(new AbstractDictionaryApiModel());
        model['@id'] = id;
        return model;
      });
    }
    return [];
  }

  /**
   * THIS DECLARATION MUST BE IN THE SAME FLOW ORDER AS IN THE VIEW BECAUSE disableRelatedChoices METHOD WILL BROKE.
   */
  public createMarketplaceFiltersForm(): UntypedFormGroup {
    if (!this.form) {
      this.form = this.fb.group({
        'project.vehicle.engine.engineType': new UntypedFormControl({value: [], disabled: false}, []),
        'project.vehicle.bodyType': new UntypedFormControl({value: [], disabled: false}, []),
        'project.vehicle.vehicleType': new UntypedFormControl({value: [], disabled: false}, []),
        'project.vehicle.make': new UntypedFormControl({value: [], disabled: true}, []),
        'project.vehicle.model': new UntypedFormControl({value: [], disabled: true}, []),
        // bodyGen: new UntypedFormControl({value: '', disabled: true}, []),
        // edition: new UntypedFormControl({value: '', disabled: true}, []),
        'project.vehicle.bodyGen.yearFrom[gte]': new UntypedFormControl(null, []),
        'project.vehicle.bodyGen.yearTo[lte]': new UntypedFormControl(null, []),
        'project.attributes.mileage[gte]': new UntypedFormControl(null, []),
        'project.attributes.mileage[lte]': new UntypedFormControl(null, []),
        'priceGross[gte]': new UntypedFormControl(null, []),
        'priceGross[lte]': new UntypedFormControl(null, []),
        'project.vehicle.engine.capacityCm3[gte]': new UntypedFormControl(null, []),
        'project.vehicle.engine.capacityCm3[lte]': new UntypedFormControl(null, []),
        'project.vehicle.engine.enginePowerBHP[gte]': new UntypedFormControl(null, []),
        'project.vehicle.engine.enginePowerBHP[lte]': new UntypedFormControl(null, []),
        invoiceVat: new UntypedFormControl(null, []),
        invoiceVatMargin: new UntypedFormControl(null, []),
        toNegotiation: new UntypedFormControl(null, []),
        leasingAssignment: new UntypedFormControl(null, []),
        'leasingInitialFee[gte]': new UntypedFormControl(null, []),
        'leasingInitialFee[lte]': new UntypedFormControl(null, []),
        'leasingMonthlyInstallment[gte]': new UntypedFormControl(null, []),
        'leasingMonthlyInstallment[lte]': new UntypedFormControl(null, []),
        'leasingRemainingInstallments[gte]': new UntypedFormControl(null, []),
        'leasingRemainingInstallments[lte]': new UntypedFormControl(null, []),
        'leasingBuyoutValue[gte]': new UntypedFormControl(null, []),
        'leasingBuyoutValue[lte]': new UntypedFormControl(null, []),
        'project.attributes.currentColour': new UntypedFormControl(null, []),
        'project.attributes.originalColour': new UntypedFormControl(null, []),
        'project.attributes.originalPaintDiffersCurrent': new UntypedFormControl(null, []),
        'project.attributes.hasPaintCoverage': new UntypedFormControl(null, []),
        'project.attributes.hasPpfWrapCoverage': new UntypedFormControl(null, []),
        'project.attributes.hasOtherWrapCoverage': new UntypedFormControl(null, []),
        'project.attributes.hasOtherPaintCoverage': new UntypedFormControl(null, []),
        'project.attributes.imported': [null],
        'project.attributes.accidentFree': [null],
        'project.attributes.damaged': [null],
        'project.attributes.rightSideSteeringWheel': [null],
        'project.attributes.registeredInCountry': [null],
        'project.attributes.asoService': [null],
        'project.attributes.registeredAsAntique': [null],
        'project.attributes.mechanicalTuning': [null],
        'project.attributes.visualTuning': [null],
        'project.attributes.truckApproval': [null],
        'project.vehicle.bodyWork.numOfSeater': new UntypedFormControl({value: [], disabled: false}, []),
        'project.vehicle.bodyWork.numOfDoors': new UntypedFormControl({value: [], disabled: false}, []),
        'project.equipment': new UntypedFormControl({value: [], disabled: false}, []),
        address: [null],
        distance: new UntypedFormControl({value: 5, disabled: false}, []),
      });
    }

    return this.form;
  }

  updateMarketplaceFiltersForm(): void {
    this.filtersSnapshot = QueryParamsService.getNormalizedQueryParametersFromSessionStorage();
    Object.keys(this.filtersSnapshot).forEach((key: string) => {
      switch (key) {
        case 'project.vehicle.engine.engineType':
          this.filtersSnapshot[key] = this.fuelTypes.filter(e => e.value.every(e => this.filtersSnapshot[key].includes(e)));
          this.form.patchValue({
            [key]: this.filtersSnapshot[key]
          });
          break;
        case 'project.vehicle.bodyType':
          this.filtersSnapshot[key] = this.filtersSnapshot[key].map(f => this.bodyTypes.find(e => f === e['@id']));
          this.form.patchValue({
            [key]: this.filtersSnapshot[key]
          });
          break
        case 'project.vehicle.vehicleType':
          const vehicleTypesValue = this.vehicleTypes.filter(e => this.filtersSnapshot['project.vehicle.vehicleType'].includes(e['@id']));
          if (vehicleTypesValue.length) {
            this.form.patchValue({
              'project.vehicle.vehicleType': vehicleTypesValue
            });
            this.form.controls['project.vehicle.vehicleType'].enable();
          }
          break;
        case 'project.vehicle.make':
          const makesValue = this.makes.filter(e => this.filtersSnapshot['project.vehicle.make']?.includes(e['@id']));
          this.form.patchValue({
            'project.vehicle.make': makesValue
          });
          this.form.controls['project.vehicle.make'].enable();
          break;
        case 'project.vehicle.model':
          const modelsValue = this.models.filter(e => this.filtersSnapshot['project.vehicle.model']?.includes(e['@id']));
          if (modelsValue.length) {
            this.form.patchValue({
              'project.vehicle.model': modelsValue
            });
            this.form.controls['project.vehicle.model'].enable();
          }
          break;
        case 'address':
          break;
        case 'addresses.coordinates':
          const addressStr = sessionStorage.getItem('marketplace.search.address') || '';
          if (addressStr.length && this.filtersSnapshot[key]) {
            const addr = JSON.parse(addressStr);
            const distance = parseInt(this.filtersSnapshot[key].split(',').pop() || "5");
            this.form.patchValue({
              address: [addr],
              distance
            });
          }
          break;
        default:
          this.form.patchValue({
            [key]: this.filtersSnapshot[key]
          });
      }

      if (this.filtersSnapshot[key].length && this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].enable();
      }
    });
  }

  public appendDictionaryFilters(queryParams: QueryParamsApiModel, vms: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[][], filterName: string = null): void {
    vms.forEach((vm: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[]) => {
      vm.forEach((item) => {
        switch (true) {
          case item instanceof MakeViewModel:
            queryParams.filters.push(new FilterParameterApiModel(filterName || 'vehicles.make', item["@id"], 'array'));
            break;
          case item instanceof VehicleTypeViewModel:
            queryParams.filters.push(new FilterParameterApiModel(filterName || 'vehicles.vehicleType', item["@id"], 'array'));
            break;
          case item instanceof BodyTypeViewModel:
            queryParams.filters.push(new FilterParameterApiModel(filterName || 'vehicles.bodyType', item["@id"], 'array'));
            break;
          case item instanceof ModelViewModel:
            queryParams.filters.push(new FilterParameterApiModel(filterName || 'vehicles.model', item["@id"], 'array'));
            break
        }
      });
    });
  }

  public getFilters(): Params {
    const filters = this.getFiltersAsHttpParams(this.form.value);
    const normalizedQueryParamsFromSessionStorage = QueryParamsService.getNormalizedQueryParametersFromSessionStorage();
    const synced = QueryParamsService.getQueryParametersFromHttpParams(filters);

    const queryParams = QueryParamsService.syncObjects(
      normalizedQueryParamsFromSessionStorage,
      synced
    );
    sessionStorage.setItem('queryParams', JSON.stringify(queryParams));
    return queryParams;
  }

  public getFiltersAsHttpParams(formValue: object): HttpParams {
    const queryParams = new QueryParamsApiModel();
    console.log(formValue);
    Object.keys(formValue).forEach((k: string) => {
      if (formValue[k] instanceof Array && formValue[k].length > 0 && (formValue[k][0] instanceof AbstractDictionaryViewModel)) {
        this.appendDictionaryFilters(queryParams, [formValue[k]], k);
        return;
      }

      if (formValue[k] instanceof Array && formValue[k].length > 0 && (formValue[k][0].hasOwnProperty('value'))) {

        if (formValue.hasOwnProperty('address') && formValue.hasOwnProperty('distance')) {
          const addrVal = formValue['address'];
          const distance = formValue['distance'];
          if (addrVal && distance && addrVal[0].hasOwnProperty('value') && typeof distance === 'number') {
            const locationVal = `${addrVal[0].value.lat},${addrVal[0].value.lon},${distance}`
            queryParams.filters.push(new FilterParameterApiModel('addresses.coordinates', locationVal, 'single'));
          }
          return;
        }

        formValue[k].forEach((selectItem: SelectItem) => {
          if (selectItem.value instanceof Array) {
            selectItem.value.forEach((id: string) => {
              queryParams.filters.push(new FilterParameterApiModel(k, id, 'array'));
            })
          } else {
            queryParams.filters.push(new FilterParameterApiModel(k, selectItem.value, 'array'));
          }
        });
      }

      if (
        k === 'address' ||
        k === 'distance'
      ) {
        return;
      }

      if (typeof formValue[k] === 'string' || typeof formValue[k] === 'number' || typeof formValue[k] === 'boolean') {
        if (formValue[k] !== '') {
          queryParams.filters.push(new FilterParameterApiModel(k, formValue[k], 'single'));
        }
      }

      if (formValue[k] instanceof Array) {
        formValue[k].forEach((value: string) => {
          queryParams.filters.push(new FilterParameterApiModel(k, value, 'array'));
        })
      }
    });

    return this.params.applyParameters(queryParams);
  }

  public getAdvancedFiltersDependencies(): Observable<any> {
    return forkJoin([
      this.getVehicleTypeCollection(),
      this.getBodyTypeCollection([]),
      this.equipmentCategoryRS.getCollection()
    ]).pipe(tap(([vehicleTypeVMs, bodyTypeVMs, equipmentCategoryRLAM]:[VehicleTypeViewModel[], BodyTypeViewModel[],  ResultListApiModel<EquipmentCategoryApiModel>]) => {
      this.vehicleTypes = vehicleTypeVMs;
      this.bodyTypes = bodyTypeVMs;
      this.equipmentCategoryCollection = equipmentCategoryRLAM.records;
      this.filtersPrefetched = true;
    }));
  }

  public resetFilters(): void {
    sessionStorage.removeItem('queryParams');
    sessionStorage.removeItem('marketplace.search.address');
    this.makes = [];
    this.models = [];
    this.form?.controls['project.vehicle.make'].disable();
    this.form?.controls['project.vehicle.model'].disable();

    this.form?.reset({
      distance: 5
    });
  }

  public enabledFilters(): number {
    return Object.values(QueryParamsService.getNormalizedQueryParametersFromSessionStorage() || {}).flat().filter(e => e !== null).length
  }

  public saveSearch(user: CurrentUserViewModel, lastResultsCount: number, btn: Button): void {
    btn.loading = true;
    btn.disabled = true;
    this.createMarketplaceFiltersForm();
    this.updateMarketplaceFilters().pipe(mergeMap(() => {
      const filters  = this.filtersSnapshot;
      const addressStr = sessionStorage.getItem('marketplace.search.address') || '{}';
      const address = typeof addressStr === "string" ? JSON.parse(addressStr) : null;
      const readableFilters = this.mapFormRawValueToArray(this.form.getRawValue());
      this.assignEquipmentLabel(readableFilters);
      const resourceAM = plainToClass(SavedAdvertisementSearchApiModel, {
        filters,
        lastResultsCount,
        readableFilters,
        address
      })
      return this.saveAdvertisementSearchRS.create(resourceAM)
    })).subscribe().add(() => {
      btn.disabled = false;
      btn.loading = false;
    });

  }


  mapFormRawValueToArray(data: Record<string, any>): { property: string; label: any; type: string, appendix?: string }[] {
    return Object.entries(data)
      .filter(([, value]) => value !== null && (!Array.isArray(value) || value.length > 0)) // Pomijamy null i puste tablice
      .map(([key, value]) => {
        let label: any;
        let type: string;
        let appendix: string = null;

        if (Array.isArray(value)) {
          const labels = value.map(item => item?.name || item?.label || item);
          type = labels.length === 1 ? "string" : "array"; // Jeśli jedna wartość, traktujemy jako string
          label = labels.length === 1 ? labels[0] : labels;
        } else if (typeof value === "object" && value?.name) {
          type = "object";
          label = value.name;
        } else {
          type = typeof value;
          label = value;
        }

        if(this.includesNormalizedKey(['priceGross', 'leasingInitialFee', 'leasingMonthlyInstallment', 'leasingBuyoutValue'], key)) {
          appendix = 'currency'
        }

        if(this.includesNormalizedKey(['mileage', 'distance'], key)) {
          appendix = 'distance_unit'
        }

        if(this.includesNormalizedKey(['capacityCm3'], key)) {
          appendix = 'cm3'
        }

        if(this.includesNormalizedKey(['enginePowerBHP'], key)) {
          appendix = 'bhp'
        }

        if(this.includesNormalizedKey(['yearFrom', 'yearTo'], key)) {
          appendix = 'year'
        }

        return { property: key, label, type, appendix };
      });
  }

  private includesNormalizedKey(keysArray: string[], key: string): boolean {
    return keysArray
      .includes(StringHelper.getLastSegment(key).replace(/\[gte\]$/, '')
        .replace(/\[lte\]$/, ''));
  }

  public fetchAdvertisementsResultCount(): void {
    QueryParamsService.updateQueryParametersFromSnapshot(this.queryParams, this.getFilters());
    this.queryParams.limit = 0;
    this.offerDPS.getCollection(this.queryParams, true, null)
      .subscribe((resultAM: ResultListApiModel<MarketplaceAdvertisementApiModel>) => {
        this.offerDPS.totalItems = resultAM.total;
        if (this.ms.modalInstance) {
          this.ms.modalInstance.resetButtonBadge = this.enabledFilters() as unknown as string;
          this.ms.modalInstance.proceedButtonBadge = this.offerDPS.totalItems as unknown as string;
        }
      });
  }

  public disableRelatedChoices(startField: string, only = []): void {
    let flag = false;
    for (const key in this.form.controls) {
      flag = key === startField && !only.length || only.includes(key);
      if (flag) {
        this.form.controls[key].reset();
        this.form.controls[key].disable({onlySelf: true, emitEvent: false});
      }
    }
  }

  assignEquipmentLabel(readableFilters: any[]) {
    const equipmentFilter = readableFilters.find(e => e.property === 'project.equipment');
    if(equipmentFilter) {
      (equipmentFilter.label as string[]).forEach((equipmentId: string, i) => {
        this.equipmentCategoryCollection.forEach((ec: EquipmentCategoryApiModel) => {
          const equipmentAM = ec.equipment.find(e => e['@id'] === equipmentId);
          if (equipmentAM) {
            equipmentFilter.label[i] = equipmentAM.name;
          }
        });
      });
    }
  }

  public unsubscribePending(): void {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  public unsubscribeAll(): void {
    this.unsubscribePending();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
