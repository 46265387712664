<p-dialog [showHeader]="isHeaderVisible" [header]="name" [(visible)]="visible"
          styleClass="app-modal {{styles.slideFrom}} {{styles.modalType}}"
          [style]="{minWidth: '20vw'}" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          (onHide)="hide()"
>
  <ng-template appModal pTemplate="content"></ng-template>
  <p-footer>
    <div *ngIf="hasButtons" class="modal-footer flex justify-content-around"
         [ngClass]="styles.light ? 'deep-orange-text' : 'text-white'">
      <button *ngIf="hasDismissButton"
              pButton
              pRipple
              type="button"
              class="p-button-plain"
              [rounded]="true"
              [outlined]="true"
              [label]="'common.button.dismiss' | translate"
              (click)="hide()"
      ></button>
      <p-button *ngIf="hasResetButton"
                type="button"
                severity="danger"
                badgeClass="p-badge-contrast p-badge-md"
                [badge]="resetButtonBadge"
                [rounded]="true"
                [outlined]="true"
                [label]="'common.button.reset' | translate"
                (click)="emitReset($event); resetCallback($event)"
      ></p-button>
      <p-button *ngIf="hasProceedButton"
                type="button"
                severity="primary"
                badgeClass="p-badge-contrast p-badge-md"
                [badge]="proceedButtonBadge"
                [rounded]="true"
                [outlined]="true"
                [label]="btnSuccessName"
                [disabled]="btnDisabled"
                (click)="processAction()"
      ></p-button>
    </div>
  </p-footer>
</p-dialog>
