import {VehicleTypeApiModel} from "../../api/models/vehicle-type.api-model";
import {AbstractDictionaryViewModel} from "./abstract-dictionary.view-model";
import {WheelDriveApiModel} from "../../api/models/wheel-drive-api.model";
import {MakeApiModel} from "../../api/models/make.api.model";

export class MakeViewModel extends AbstractDictionaryViewModel<MakeApiModel> {
  constructor(protected apiModel: MakeApiModel) {
    super(apiModel);
  }
}
