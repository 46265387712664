<div class="end-cap" [ngClass]="wrapperClass">
  <button pButton pRipple (click)="triggerClick($event)" [icon]="showLoader ? 'pi pi-spin pi-spinner' : icon" [ngClass]="pointerClass" aria-hidden="true" [disabled]="disabled" [label]="label"><ng-content></ng-content></button>
  <button pButton pRipple *ngIf="secondButton && !menuItems.length"
          [icon]="secondButtonIcon"
          [disabled]="disabled"
          [ngClass]="secondButtonPointerClass"
          [label]="secondButtonLabel"
          [iconPos]="'right'"
          (click)="triggerSecondButtonClick($event)"
  ></button>
  <button pButton pRipple *ngIf="menuItems.length"
          [icon]='secondButtonIcon'
          [disabled]="disabled"
          [ngClass]="secondButtonPointerClass"
          [label]="secondButtonLabel"
          [iconPos]="'right'"
          (click)="triggerSecondButtonClick($event, menu)"
  ></button>
  <p-menu #menu [model]="menuItems" [popup]="true" [appendTo]="'body'"></p-menu>
</div>
