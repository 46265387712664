import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {SavedAdvertisementSearchApiModel} from "../models/saved-advertisement-search.api-model";

@Injectable()
export class SavedAdvertisementSearchRestService {
  private readonly resource: string = '/saved_advertisement_searches';

  constructor(
    private http: HttpClient,
    private params: QueryParamsService
  ) {
  }

  public create(resourceAM: SavedAdvertisementSearchApiModel): Observable<SavedAdvertisementSearchApiModel> {
    return this.http.post<SavedAdvertisementSearchApiModel>(environment.apiUrl + this.resource, resourceAM)
      .pipe(map((result: SavedAdvertisementSearchApiModel) => plainToClass(SavedAdvertisementSearchApiModel, result)))
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<SavedAdvertisementSearchApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<SavedAdvertisementSearchApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<SavedAdvertisementSearchApiModel>(SavedAdvertisementSearchApiModel), result as object)));
  }


  public put(savedAdvertisementSearchAM: SavedAdvertisementSearchApiModel): Observable<SavedAdvertisementSearchApiModel> {
    return this.http.put<SavedAdvertisementSearchApiModel>(environment.apiUrl + savedAdvertisementSearchAM['@id'], savedAdvertisementSearchAM)
      .pipe(map((result: SavedAdvertisementSearchApiModel) => plainToClass(SavedAdvertisementSearchApiModel, result)))
  }

  public delete(savedAdvertisementSearchAM: SavedAdvertisementSearchApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + savedAdvertisementSearchAM['@id']);
  }

  public getUserSubResourceCollection(userId: string): Observable<ResultListApiModel<SavedAdvertisementSearchApiModel>> {
    return this.http.get<ResultListApiModel<SavedAdvertisementSearchApiModel>>(environment.apiUrl + userId + this.resource)
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<SavedAdvertisementSearchApiModel>(SavedAdvertisementSearchApiModel), result as object)));
  }
}
