<p-card styleClass="mb-2" [style]="{width: '100%'}">
  <div class="grid grid-nogutter px-1">
    <div class="col-10 flex">
      <app-thumbnail
        (click)="goToPage(resource)"
        size="sm"
        [circle]="true"
        [imgSrc]="resource.thumbnail"
      ></app-thumbnail>
      <div class="col-10 mt-0 pt-0">
        <h4 class="mb-0">
          <a (click)="goToPage(resource)">
            {{resource.alias || resource.name}}
          </a>
        </h4>
        <div *ngIf="resource.alias">{{resource.name}}</div>
        <ng-container *ngIf="resource.type === 'project'">
          <div >{{'shared.top_bar.search_list.from_garage' | translate}} <a
            (click)="goToPage(resource)">
            <ng-container *ngFor="let user of resource.users; let last = last">
              {{user.username}}<ng-container *ngIf="!last">,</ng-container>
            </ng-container>
          </a>
          </div>
        </ng-container>
        <ng-container *ngIf="resource.type === 'event'">
          <div>
            {{'shared.top_bar.search_list.organized_by' | translate}}
            <ng-container *ngFor="let user of resource.users; let last = last">
              {{user.username}}<ng-container *ngIf="!last">,</ng-container>
            </ng-container>
          </div>
          <div>
            {{'shared.top_bar.search_list.organized_on' | translate}}
            <ng-container *ngFor="let user of resource.subject.eventDates; let i = index; let last = last; let first = first">
              <ng-container *ngIf="first">
                {{resource.subject.eventDates[i].start|date}}
              </ng-container>
              <ng-container *ngIf="resource.subject.eventDates.length > 1 && last">
                - {{resource.subject.eventDates[i].end|date}}
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="resource.type  === 'marketplace'">
          <h3 class="font-medium text-sm my-1">{{'common.vehicle_offer'|translate}} {{ resource.subject.title }} ({{resource.subject.project.attributes.firstRegistrationInCountry|date:'yyyy'}})</h3>
          <div class="inline-flex flex-nowrap gap-2 text-xs font-light text-overflow-ellipsis">
            <span><i class="icon-engine"></i>{{resource.subject.project.attributes.mileage}} {{'common.km'|translate}}</span>
            <span>|</span>
            <span><i class="fas fa-gauge-high mr-2"></i>{{resource.subject.project.vehicle.engine.capacityCm3}} {{'common.cm3' | translate}}</span>
            <span>|</span>
            <span><i class="fas fa-gas-pump fas-ml-1 mr-2"></i>{{resource.subject.project.vehicle.engine.engineType|fuelType}}</span>
          </div>
          <h2 class="font-medium text-sm mt-2 mb-1">
            <i class="fas fa-wallet mr-2 price"></i><span class="mr-2 price">{{ resource.subject.priceGross| currency:resource.subject.currency:'code':'1.0' }}</span>
            <ng-container *ngIf="resource.subject.toNegotiation"><small class="text-color font-normal">{{'common.to_negotiation'|translate}}</small></ng-container>
          </h2>
        </ng-container>
      </div>
    </div>
    <div class="col-2 flex justify-content-end align-items-start">
      <i class="pi pi-times" *ngIf="hasLastQueriedResources" (click)="removeSearchLog($event, resource)"></i>
    </div>
  </div>
</p-card>
