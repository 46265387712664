import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ViewChild,
  Output, EventEmitter, afterNextRender
} from '@angular/core';
import {
  AbstractControl,
  FormControl, UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {ModalInterface} from '../../modal.interface';
import {Subscription, Observable, Subject, mergeMap, of, forkJoin} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {ResultsListTabInterface} from '../../../../core/components/search-results-list/results-list-tab.interface';
import {RxFormArray, RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ModalState} from '../../modal-state.enum';
import {SearchTabsEnum} from "../../../../core/components/search-results-list/search-tabs.enum";
import {VehicleTypeViewModel} from "../../../../core/models/vehicle-type.view-model";
import {MakeViewModel} from "../../../../core/models/make.view-model";
import {BodyTypeViewModel} from "../../../../core/models/body-type.view-model";
import {ModelViewModel} from "../../../../core/models/model.view-model";
import {BodyGenViewModel} from "../../../../core/models/body-gen.view-model";
import {VehicleViewModel} from "../../../../features/user/garage/vehicle.view-model";
import {FiltersDataProviderService} from "../../../services/filters.data-provider.service";
import {MultiSelect} from "primeng/multiselect";
import {AutoComplete, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {SelectItem} from "primeng/api";
import {MapComponent} from "../../../map/map.component";
import {MapService} from "../../../map/map.service";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {Params} from "@angular/router";
import {AbstractDictionaryApiModel} from "../../../../api/models/abstract-dictionary.api-model";
import {AbstractDictionaryViewModel} from "../../../../core/models/abstract-dictionary.view-model";
import {OffersDataProviderService} from "../../../marketplace/offers.data-provider.service";
import {ModalComponent} from "../../modal.component";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../modal.service";


@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, AfterViewInit, ModalInterface, OnDestroy {
  get form(): UntypedFormGroup {
    return this.filtersDPS.form;
  }

  set form(value: UntypedFormGroup) {
    this.filtersDPS.form = value;
  }

  constructor(
    private readonly fb: RxFormBuilder,
    private readonly mapService: MapService,
    public readonly filtersDPS: FiltersDataProviderService,
    public readonly offerDPS: OffersDataProviderService,
    public readonly translate: TranslateService,
    public readonly ms: ModalService
  ) {

  }

  @Input()
  public data: { selectedTab: ResultsListTabInterface };
  @Output() onReset: EventEmitter<void> = new EventEmitter<void>();
  public currentDate: Date = new Date();
  public state: Observable<ModalStateEvent>;
  public models: ModelViewModel[];
  public bodyGens: BodyGenViewModel[];
  public vehicle: VehicleViewModel;
  public searchResults: SelectItem[] = [];
  public subject: Subject<ModalStateEvent>;
  public readonly SearchTabsEnum = SearchTabsEnum;
  public parent: ModalComponent;

  @ViewChild(MapComponent)
  public mapComponent: MapComponent;
  @ViewChild('marketplaceAddress')
  public marketplaceAddress: AutoComplete;
  @ViewChildren(MultiSelect)
  private queryList: QueryList<MultiSelect>;
  public subscription: Subscription;

  public ngOnInit(): void {
    this.createForm();
    this.parent = this.ms.modalInstance;
    this.parent.hasResetButton = true;
    this.filtersDPS.subject = this.subject = this.ms.modalInstance.modalSubject
    this.state = this.subject.asObservable();
  }

  ngAfterViewInit(): void {
    this.queryList.forEach((e: MultiSelect) => {
      e.el.nativeElement.removeAttribute('pautofocus');
      e.el.nativeElement.setAttribute('inputmode', 'none');
      e.focusInputViewChild?.nativeElement.removeAttribute('pautofocus');
      e.focusInputViewChild?.nativeElement.setAttribute('inputmode', 'none');
    });
    this.subscribeToModalStates();
    this.filtersDPS.subscriptions.push(this.filtersDPS.updateMarketplaceFilters().subscribe(() => {
      this.ms.emitState(ModalState.VALID, null);
      this.subscribeToFormValueChanges();
      this.filtersDPS.fetchAdvertisementsResultCount();
    }));
  }


  subscribeToFormValueChanges() {
    this.filtersDPS.subscriptions.push(this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe((formValue) => {
      this.filtersDPS.fetchAdvertisementsResultCount();
    }));
  }

  private subscribeToModalStates() {
    this.filtersDPS.subscriptions.push(this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.RESET:
          this.resetFilters();
          break;
        case ModalState.VALID:
          const addr = this.form.get('address').value;
          if (this.mapComponent && addr && addr.length) {
            this.mapComponent.isRenderedEmitter.subscribe(() => {
              this.updateMap(addr[0].value);
            });
          }
          break;
        case ModalState.PENDING:
          this.filtersDPS.subject.next(new ModalStateEvent(ModalState.SUCCESS, this.getFilters()));
          break;
        case ModalState.HIDE:
          this.filtersDPS.updateMarketplaceFiltersForm();
          break;
      }
    }));
  }

  private createForm() {
    switch (this.data.selectedTab.type) {
      case SearchTabsEnum.MARKETPLACE:
        this.createMarketplaceFiltersForm();
        break;
      case SearchTabsEnum.EVENTS:
        this.createEventFiltersForm();
        break;
      default:
        this.createEventFiltersForm();
        break;
    }
  }

  private createMarketplaceFiltersForm() {
    this.form = this.filtersDPS.createMarketplaceFiltersForm();
  }

  private createEventFiltersForm() {
    this.form = this.fb.group({
      address: new FormControl('', [RxwebValidators.required()]),
      distance: new FormControl(1000, [RxwebValidators.required()]),
      hashtags: new FormControl('', [RxwebValidators.required()]),
    });
  }

  public ngOnDestroy(): void {
    if (this.parent) {
      this.parent.hasResetButton = false;
    }

    this.filtersDPS.unsubscribeAll();
  }

  public seekMakes(vehicleTypeVMs: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[]): void {
    this.subscription = this.filtersDPS.seekMakes(vehicleTypeVMs).subscribe()
  }

  public seekModels(vehicleTypeVM: VehicleTypeViewModel[], makeVM: MakeViewModel[]): void {
    this.subscription = this.filtersDPS.seekModels(vehicleTypeVM, makeVM).subscribe()
  }

  public seekBodyGens(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel
  ): void {
    this.subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.filtersDPS.disableRelatedChoices('bodyGen');
    this.filtersDPS.unsubscribePending();
    this.subscription = this.filtersDPS.getBodyGenCollection(vehicleTypeVM, makeVM, bodyTypeVM, modelVM).subscribe(
      (bodyGenVMs: BodyGenViewModel[]) => {
        this.bodyGens = bodyGenVMs;
        this.form.get('bodyGen').enable();
        this.subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public getFilters(): Params {
    return this.filtersDPS.getFilters()
  }

  searchPlaces(event: any) {
    const sub = this.mapService.subscribeToQuerySubject().subscribe((results: any[]) => {
      this.searchResults = results.map(result => ({label: result.display_name, value: result}));
      if (!this.searchResults.length) {
        this.form.get('address').setErrors({incorrect: true, message: 'This is not valid place'});
      }
      sub.unsubscribe();
    });
    this.mapService.searchPlaces(event.query);
  }

  selectPlace(event: AutoCompleteSelectEvent) {
    sessionStorage.setItem('marketplace.search.address', JSON.stringify(event.value));
    const addrInput = this.form.get('address')
    addrInput.setValue([event.value]);
    addrInput.setErrors(null);
    const item: any = event.value.value;
    this.updateMap(item);
  }

  updateMap(item: any): void {
    const iMarker = {lat: item.lat as number, lon: item.lon as number};

    this.mapComponent.layerMarkers.forEach((marker) => {
      this.mapComponent.removeMarker(marker);
    });

    this.mapComponent.layerCircles.forEach((circle) => {
      this.mapComponent.removeCircle(circle);
    });

    this.mapComponent.addMarker(iMarker);
    this.mapComponent.updateMapCenter(iMarker);
    this.mapComponent.addCircleRadius(iMarker, this.form.get('distance').value);
  }

  updateRadius(): void {
    this.mapComponent.updateCircleRadius(this.form.get('distance').value);
  }

  getAddrOptionValue(item: SelectItem): any {
    return item.value;
  }

  getAddrOptionLabel(item: any): any {
    return item.label;
  }

  public resetFilters(): void {
    this.filtersDPS.resetFilters();
    this.onReset.emit();
  }

  public enabledFilters(): number {
    return this.filtersDPS.enabledFilters();
  }

  public getNumOfSeaterControl(): FormControl | (FormControl & AbstractControl) | (undefined & FormControl) | (undefined & FormControl & AbstractControl) {
    return this.form.controls['project.vehicle.bodyWork.numOfSeater'] as FormControl;
  }

  public getNumOfDoorsControl(): FormControl | (FormControl & AbstractControl) | (undefined & FormControl) | (undefined & FormControl & AbstractControl) {
    return this.form.controls['project.vehicle.bodyWork.numOfDoors'] as FormControl;
  }


  public getEquipmentControl(): FormControl | (FormControl & AbstractControl) | (undefined & FormControl) | (undefined & FormControl & AbstractControl) {
    return this.form.controls['project.equipment'] as FormControl;
  }
}
