import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../app.component";
import {environment} from "../../../environments/environment";
import {AppConfigComponent} from "./app.config.component";
import {MenuService} from "./app.menu.service";
import {TokenDataProviderService} from "../../core/services/token-data-provider.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>
    <div class="bottom-container width-100 bottom-0 absolute">
      <ul class="layot-topbar-items">
        <li class="layout-topbar-action-item">
          <a [routerLink]="['/login']" class="flex flex-row justify-content-center align-items-center" pRipple
             (click)="logout($event)">
            <i class="pi pi-power-off" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
            <span>{{ 'shared.inline_menu.logout' | translate }}</span>
          </a>
        </li>
      </ul>
      <hr class="width-100 m-0">
      <div class="width-100 p-3 flex align-items-center gap-5">
        <p-button type="button" (click)="appMenuService.configMenuActive = true" icon="pi pi-cog"
                  styleClass="layout-config-button p-button-icon-only p-button-rounded p-button-outlined p-2">
        </p-button>
        <span>WTG v. {{ date | date: 'YYYY' }}{{ '.' + version }}</span>
      </div>

    </div>
  `
})
export class AppMenuComponent implements OnInit {
  public date: Date = new Date();
  public version: number = environment.version;
  model: any[];

  constructor(
    public app: AppComponent,
    public appMenuService: MenuService,
    private ts: TokenDataProviderService,
    private readonly translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.model = [
      // {
      //   label: this.translate.instant('common.support'), icon: 'pi pi-fw pi-home',
      //   items: [
      //     {
      //       label: this.translate.instant('common.faq'),
      //       icon: 'pi pi-fw pi-question',
      //       routerLink: ['/terms']
      //     },
      //     {
      //       label: this.translate.instant('common.support_contact'),
      //       icon: 'pi pi-fw pi-exclamation-circle',
      //       routerLink: ['/privacy']
      //     }
      //   ]
      // },
      // {
      //   label: this.translate.instant('common.legal_notices'), icon: 'pi pi-fw pi-home',
      //   items: [
      //     {
      //       label: this.translate.instant('shared.inline_menu.terms'),
      //       icon: 'pi pi-fw pi-file-o',
      //       routerLink: ['/terms']
      //     },
      //     {
      //       label: this.translate.instant('features.privacy.privacy'),
      //       icon: 'pi pi-fw pi-shield',
      //       routerLink: ['/privacy']
      //     }
      //   ]
      // },
      // {
      //     label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
      //     items: [
      //         {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'], badge: '6', badgeClass: 'p-badge-danger'},
      //         {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
      //         {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate']},
      //         {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
      //         {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'], badge: '6', badgeClass: 'p-badge-help'},
      //         {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
      //         {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
      //         {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
      //         {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
      //         {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
      //         {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu']},
      //         {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
      //         {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
      //         {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
      //         {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
      //     ]
      // },
      // {
      //     label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
      //     items: [
      //         {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'], badge: '6', badgeClass: 'p-badge-warning'},
      //         {label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display']},
      //         {label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation']},
      //         {label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox']},
      //         {label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons']},
      //         {label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text']},
      //         {label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets']},
      //         {label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid']},
      //         {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing']},
      //         {label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography']}
      //     ]
      // },
      // {
      //     label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
      //     items: [
      //         {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
      //         {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
      //         {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']},
      //         {
      //             label: 'Landing', icon: 'pi pi-fw pi-globe', badge: '2', badgeClass: 'p-badge-warning',
      //             items: [
      //                 {label: 'Static', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank'},
      //                 {label: 'Component', icon: 'pi pi-fw pi-globe', routerLink: ['/landing']}
      //             ]
      //         },
      //         {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login']},
      //         {label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice']},
      //         {label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help']},
      //         {label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error']},
      //         {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound']},
      //         {label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access']},
      //         {label: 'Contact Us', icon: 'pi pi-fw pi-pencil', routerLink: ['/contactus']},
      //         {label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty']}
      //     ]
      // },
      // {
      //     label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
      //     items: [
      //         {
      //             label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
      //             items: [
      //                 {
      //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
      //                     items: [
      //                         {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
      //                         {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
      //                         {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
      //                     ]
      //                 },
      //                 {
      //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
      //                     items: [
      //                         {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'}
      //                     ]
      //                 },
      //             ]
      //         },
      //         {
      //             label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
      //             items: [
      //                 {
      //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
      //                     items: [
      //                         {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
      //                         {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
      //                     ]
      //                 },
      //                 {
      //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
      //                     items: [
      //                         {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
      //                     ]
      //                 },
      //             ]
      //         }
      //     ]
      // },
      // {
      //     label: 'Start', icon: 'pi pi-fw pi-download',
      //     items: [
      //         {
      //             label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
      //         },
      //         {
      //             label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
      //         }
      //     ]
      // }
    ];
  }

  public logout(event: Event): void {
    this.ts.logout();
  }
}
