<form [formGroup]="resetForm" (ngSubmit)="reset()" novalidate>
  <h1 class="text-center m-1">
    <img src="assets/layout/images/wtg-logo-transparent-white.webp" alt="watch-this-garage-logo" class="app-logo">
    <small class="-mt-3" style="color: #4285F4;">{{'auth.password_reset.title' | translate}}</small>
  </h1>
  <div class="col-12 p-fluid">
    <span class="p-float-label p-input-icon-left">
      <i class="pi pi-envelope"></i>
      <input type="text" pInputText id="usernameOrEmail" formControlName="usernameOrEmail">
        <label for="usernameOrEmail">{{'auth.password_reset.username_or_email' | translate}}</label>
    </span>
  </div>
  <div class="col-12 app-form-error" *ngIf="resetForm.controls['usernameOrEmail'].invalid && resetForm.controls['usernameOrEmail'].touched">
    <p *ngIf="resetForm.controls['usernameOrEmail'].errors.required">
      {{'auth.password_reset.validation.username_or_email.required' | translate}}
    </p>
    <div *ngIf="resetForm.controls['usernameOrEmail'].errors.custom">{{resetForm.controls['usernameOrEmail'].errors.custom}}
    </div>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            type="submit"
            icon="pi pi-envelope"
            iconPos="left"
            class="p-button-primary p-button-outlined p-button-rounded"
            [disabled]="!(resetForm.controls['usernameOrEmail'].dirty && resetForm.controls['usernameOrEmail'].valid)"
            [label]="'common.button.send' | translate"></button>
  </div>
  <div class="col-12 p-fluid">
    <button pButton pRipple
            type="button"
            icon="pi pi-arrow-left"
            iconPos="left"
            class="p-button-danger p-button-outlined p-button-rounded"
            [routerLink]="['/login']"
            [label]="'common.button.back' | translate"></button>
  </div>
</form>
