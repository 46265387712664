import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TokenDataProviderService} from '../../core/services/token-data-provider.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MessageApiModel} from '../models/message.api.model';
import {UserPasswordApiModel} from '../models/user-password.api-model';
import {ResultListApiModel} from '../models/result-list.api.model';
import {QueryParamsApiModel} from '../models/query-params-api.model';
import {QueryParamsService} from '../../core/services/query-params.service';
import {UserApiModel} from '../models/user.api-model';
import {UserStatisticsApiModel} from '../models/user-statistics.api-model';
import {ReactionApiModel} from "../models/reaction.api.model";
import {FollowApiModel} from "../models/follow.api-model";
import {FeedApiModel} from "../models/feed.api.model";
import {MarketplaceAdvertisementApiModel} from "../models/marketplace-advertisement.api.model";


@Injectable()
export class UserRestService {
  public readonly resource: string = '/users';

  public user: UserApiModel;

  constructor(
    private http: HttpClient,
    public ts: TokenDataProviderService,
    private params: QueryParamsService
  ) {
      this.user = this.ts.getJwtUser();
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel(), groups: string[] = []): Observable<ResultListApiModel<UserApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<UserApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<UserApiModel>(UserApiModel), result as object, {groups})));
  }

  public get(id: string): Observable<UserApiModel> {
    return this.http.get<UserApiModel>(environment.apiUrl + id)
      .pipe(map((successResponse) => plainToClass(UserApiModel, successResponse)));
  }

  public getStatistics(id: string): Observable<UserStatisticsApiModel> {
    return this.http.get<UserStatisticsApiModel>(environment.apiUrl + id + '/statistics')
      .pipe(map((successResponse) => plainToClass(UserStatisticsApiModel, successResponse)));
  }

  public put(id: string, userApiModel: UserApiModel): Observable<UserApiModel> {
    return this.http.put<UserApiModel>(environment.apiUrl + id, userApiModel)
      .pipe(map((successResponse: UserApiModel) => plainToClass(UserApiModel, successResponse)));
  }

  public patch(id: string, userApiModel: UserApiModel): Observable<UserApiModel> {
    return this.http.patch<UserApiModel>(environment.apiUrl + id, userApiModel)
      .pipe(map((successResponse: UserApiModel) => plainToClass(UserApiModel, successResponse)));
  }

  public changePassword(userApiModel: UserPasswordApiModel): Observable<MessageApiModel> {
    return this.http.post(environment.apiUrl + `/users/password-change`, userApiModel).pipe(map(
      successResponse => plainToClass(MessageApiModel, successResponse)));
  }

  public getReceivedLikesSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ReactionApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ReactionApiModel>>(environment.apiUrl + userId + '/received_reactions')
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ReactionApiModel>(ReactionApiModel), result as object)));
  }

  public getReceivedFollowsSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + userId + '/received_follows', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }

  public getFollowsSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + userId + '/follows', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }

  public getReactionsSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<ReactionApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<ReactionApiModel>>(environment.apiUrl + userId + '/reactions', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<ReactionApiModel>(ReactionApiModel), result as object)));
  }

  public delete(userAM: UserApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + userAM['@id']);
  }

  public getMarketplaceAdvertisementsSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<MarketplaceAdvertisementApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<MarketplaceAdvertisementApiModel>>(environment.apiUrl + userId + '/marketplace_advertisements', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<MarketplaceAdvertisementApiModel>(MarketplaceAdvertisementApiModel), result as object)));
  }

  public getSavedMarketplaceAdvertisementsSubResourceCollection(userId: string, queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<FollowApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<FollowApiModel>>(environment.apiUrl + userId + '/saved_marketplace_advertisements', {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<FollowApiModel>(FollowApiModel), result as object)));
  }
}
