import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter, OnDestroy, HostListener, Inject, AfterViewInit
} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {ModalService} from './modal.service';
import {ModalInterface} from './modal.interface';
import {ModalDirective} from './directives/modal.directive';
import {ModalItemModel} from './modal-item.model';
import {ModalState} from './modal-state.enum';
import {tap} from 'rxjs/operators';
import {ModalStateEvent} from './modal-state-event';
import {Dialog} from 'primeng/dialog';
import {TranslateService} from '@ngx-translate/core';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';
import {DOCUMENT} from '@angular/common';
import {LoaderService} from '../../core/services/loader.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  get isHeaderVisible(): boolean {
    return this._isHeaderVisible;
  }

  set isHeaderVisible(value: boolean) {
    this._isHeaderVisible = value;
  }

  public visible = false;
  public modalStateEvent: ModalStateEvent = new ModalStateEvent(ModalState.INIT);

  get internalState(): Observable<ModalStateEvent> {
    return this._internalState.pipe(tap((modalState: ModalStateEvent) => {
      this.modalStateEvent = modalState;
    }));
  }

  get hasButtons(): boolean {
    return this._hasButtons;
  }

  set hasButtons(value: boolean) {
    this._hasButtons = value;
  }

  get btnSuccessName(): string {
    return this.ms.btnSuccessName;
  }

  set btnSuccessName(value: string) {
    this.ms.btnSuccessName = value ?? this.translate.instant('shared.modal.modal_component.button.proceed');
  }

  get styles(): { slideFrom: string; modalType: string; size: string, light?: boolean } {
    return this.ms.styles || this._styles;
  }

  @Input() set styles(value: { slideFrom: string; modalType: string; size: string, light?: boolean }) {
    this._styles = value;
  }

  @Input() component: ModalItemModel;
  @Output() state: EventEmitter<ModalStateEvent> = new EventEmitter<ModalStateEvent>();
  @ViewChild(Dialog) public contentModal: Dialog;
  @ViewChild(ModalDirective) appModal: ModalDirective;
  public name: string;
  private _isHeaderVisible: boolean = true;
  public spinnerState: boolean = false;
  public btnDisabled: boolean = false;
  private viewContainerRef: ViewContainerRef;
  public modalChildComponentRef: ModalInterface;
  public modalSubject: Subject<ModalStateEvent>;
  private _internalState: Observable<ModalStateEvent>;
  private _hasButtons: boolean = true;
  public hasResetButton: boolean = false;
  public hasDismissButton: boolean = true;
  public hasProceedButton: boolean = true;
  public proceedButtonBadge: string = null;
  public resetButtonBadge: string = null;
  public resetCallback: Function = (event: Event) => {
  };
  private _styles: { slideFrom: string, modalType: string, size: string, light?: boolean } = {
    slideFrom: 'top',
    modalType: 'modal-top',
    size: 'modal-fluid',
    light: true
  };
  public rendered: boolean;

  constructor(
    public ms: ModalService,
    private translate: TranslateService,
    private ls: LoaderService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    const currentCode = Number(event.code);
    let currentKey = event.key;
    if (!currentKey) {
      currentKey = String.fromCharCode(currentCode);
    }
    if (currentKey === 'Escape') {
      this.enableBodyScroll();
    }
  }

  public ngOnInit(): void {
    this.ms.modalInstance = this;
    this.modalSubject = new Subject<ModalStateEvent>();
    this._internalState = this.modalSubject.asObservable();
  }

  ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    this.modalSubject?.unsubscribe();
    this.enableBodyScroll();
  }

  public show(): void {
    this.ms.modalInstance = this;
    this.modalSubject = new Subject<ModalStateEvent>();
    this._internalState = this.modalSubject.asObservable();
    this.modalSubject.next(new ModalStateEvent(ModalState.INIT));
    this.loadComponent();
    this.watchInternalState();
    this.disableBodyScroll();
    if (window.innerWidth < 1024) {
      this.contentModal.maximize();
    }
    this.visible = true;
    setTimeout(() => {
      this.dialogHeight();
    }, 100);
  }

  public hide(): void {
    this.modalSubject.next(new ModalStateEvent(ModalState.HIDE));
    this.visible = false;
    this.rendered = false;
    this.enableBodyScroll();
  }

  public emitState(state: ModalStateEvent): void {
    this.modalSubject.next(state);
  }

  public removeStyles(): void {
    this.ms.styles = undefined;
  }

  public resetAction(): void {
    this.modalStateEvent.status = ModalState.PENDING;
    this.modalSubject.next(this.modalStateEvent);
  }

  public processAction(): void {
    this.modalStateEvent.status = ModalState.PENDING;
    this.modalSubject.next(this.modalStateEvent);
  }

  private emitSuccess(): void {
    this.modalStateEvent.status = ModalState.SUCCESS;
    this.state.emit(this.modalStateEvent);
    this.visible = false;
    this.enableBodyScroll();
  }

  private loadComponent(): void {
    const modalChild = this.component;
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalChild.component);
    this.viewContainerRef = this.appModal.viewContainerRef;
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(modalChild.component);
    this.modalChildComponentRef = (componentRef.instance as ModalInterface);
    this.modalChildComponentRef.data = modalChild.data;
    this.modalChildComponentRef.parent = this;
    this.modalChildComponentRef.state = this._internalState;
    this.modalChildComponentRef.subject = this.modalSubject;
    console.log('test', this, this.modalChildComponentRef);
  }

  private watchInternalState(): void {
    const sub = this._internalState.subscribe((value: ModalStateEvent) => {
      switch (value.status) {
        case ModalState.PROCESS:
          this.ls.show();
          break;
        case ModalState.PROCESS_END:
          this.ls.hide();
          break;
        case ModalState.INIT:
        case ModalState.VALID:
          this.btnDisabled = false;
          this.ls.hide();
          break;
        case ModalState.PENDING:
          this.ls.show();
          break;
        case ModalState.SUCCESS:
          this.ls.hide();
          this.emitSuccess();
          sub.unsubscribe();
          break;
        case ModalState.INVALID:
          this.btnDisabled = true;
          this.ls.hide();

          break;
      }
    });
  }

  disableBodyScroll(): void {
    disableBodyScroll(this.document.body);
  }

  enableBodyScroll(): void {
    enableBodyScroll(this.document.body);
  }

  dialogHeight(): number {
    const nodeList = this.document.querySelectorAll('.app-modal');

    if (nodeList.length && nodeList.item(0).clientHeight) {
      this.rendered = true;
      return nodeList.item(0).clientHeight;
    }
    return 0;
  }

  emitReset(event: Event): void {
    this.emitState(new ModalStateEvent(ModalState.RESET));
  }
}
