import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter, HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {ModalInterface} from "../../modal.interface";
import {Observable, Subject} from "rxjs";
import {ModalStateEvent} from "../../modal-state-event";
import {FormsModule, UntypedFormGroup, Validators} from "@angular/forms";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {ProjectViewModel} from "../../../../features/user/garage/project.view-model";
import {ModalState} from "../../modal-state.enum";
import {DescriptionPostViewModel} from "../../../../features/user/project/about/details/description-post.view-model";
import {isPlatformBrowser, JsonPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {NgxInfiniteGridModule} from "@egjs/ngx-infinitegrid";
import {NgxFrameInfiniteGridComponent} from "@egjs/ngx-infinitegrid/lib/grids/ngx-frame-infinitegrid.component";
import {OnRequestAppend} from "@egjs/infinitegrid";
import {tap} from "rxjs/operators";
import {PipesModule} from "../../../../core/pipes/pipes.module";
import {CheckboxModule} from "primeng/checkbox";
import {MediaObjectDataProviderService} from "./media-object.data-provider.service";
import {MediaObjectViewModel} from "../../../../core/models/media-object.view-model";
import {MediaObjectInterface} from "../../../../core/interfaces/media-object.interface";

@Component({
  selector: 'app-media-choice',
  templateUrl: './media-choice.component.html',
  styleUrls: ['./media-choice.component.scss'],
  imports: [
    NgForOf,
    JsonPipe,
    NgxInfiniteGridModule,
    PipesModule,
    NgIf,
    NgOptimizedImage,
    CheckboxModule,
    FormsModule
  ],
  standalone: true,
  providers: [MediaObjectDataProviderService]
})
export class MediaChoiceComponent implements ModalInterface, OnInit, AfterViewInit {
  data: any;
  subject: Subject<ModalStateEvent>;
  state: Observable<ModalStateEvent>;
  items: MediaObjectInterface[] = []; //Array.from({length: 20}, (v, i) => ({name: `Item ${i + 1}`}));
  containerWidth: number = 0;
  public page = 1;
  private endOfResults = false;
  private totalItems = 9;
  public form: UntypedFormGroup;
  @Input() public projectVM: ProjectViewModel;
  @Input() public descriptionPost: DescriptionPostViewModel;
  @Input() public reference: HTMLElement;
  @Input() public selectedMedia: MediaObjectInterface[] = [];
  @Output() onAdd: EventEmitter<DescriptionPostViewModel> = new EventEmitter()
  @ViewChild('ig', {static: true}) ig: NgxFrameInfiniteGridComponent;
  @ViewChild('container', {static: true}) container: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setContainerWidth();
  }

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private fb: RxFormBuilder,
    private mediaObjectDPS: MediaObjectDataProviderService
  ) {
  }

  ngOnInit(): void {
    // this.projectVM = this.data['projectVM'];
    // this.descriptionPost = this.data['descriptionPost'];
    // this.createForm();
    // this.state.subscribe((mse: ModalStateEvent) => {
    //   switch (mse.status) {
    //     case ModalState.PENDING: this.submit();
    //   }
    // })
  }

  onRequestAppend(event: OnRequestAppend) {
    if (this.endOfResults || (this.totalItems && this.items.length >= this.totalItems)) {
      return;
    }
    // tslint:disable-next-line:no-non-null-assertion
    // const nextGroupKey = ((+event.groupKey! || 0) + 1);
    event.wait();
    // event.currentTarget.appendPlaceholders(18, nextGroupKey);

    this.mediaObjectDPS.getMediaObjectSubresourceCollection('projects/' + this.projectVM.slug, this.page)
      .pipe(tap(() => this.totalItems = this.mediaObjectDPS.totalItems))
      .subscribe((mediaObjectVMs: MediaObjectViewModel[]) => {
        // this.ls.hide();
        if (!mediaObjectVMs.length) {
          this.endOfResults = true;
          // this.timelineDPS.endOfResults = true;
        }

        // this.ig.renderer.update();
        this.items = [...this.items, ...mediaObjectVMs];

        // const newRenderItems = postVMs.map((postVM: PostViewModel, i: number) => {
        //   return {groupKey: nextGroupKey, key: i, post: postVM};
        // });
        // this.renderItems = [...this.renderItems, ...newRenderItems];
        this.page++;
        // this.timelineDPS.items = this.posts;
        // this.timelineDPS.page = this.page;
        this.ig.renderItems();
        this.preSelectItems();
        event.ready();
      });
  }

  ngAfterViewInit(): void {
    // this.ig.renderItems();
    // this.ig.updateItems();
    console.log(this.items, this.ig);
    // this.setContainerWidth();
    // this.onScroll(); // Sprawdzenie początkowej pozycji

  }

  private createForm() {
    // const validators: Validators[] = [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})];
    this.form = this.fb.group(
      {
        content: [null, [RxwebValidators.minLength({value: 3})]],
      }
    );

    if (this.descriptionPost) {
      this.form.get('content').setValue(this.descriptionPost.content);
    }

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      }
    })
  }

  setContainerWidth(): void {
    this.containerWidth = this.ig.elementRef.nativeElement.clientWidth;
    this.ig.renderItems();
  }

  public readonly isPlatformBrowser = isPlatformBrowser;

  public toggleSelectItem(item: any): void {
    const selectedItem = this.items.find(e => e["@id"] === item["@id"]);
    selectedItem.selected = !selectedItem.selected;
    item.selected = selectedItem.selected;
    this.selectedMedia = this.items.filter(e => e.selected);
  }

  public getMediaObject(item: any): MediaObjectInterface {
    return item as MediaObjectInterface;
  }


  public preSelectItems(): void {
    this.selectedMedia.forEach(sm => {
      const itemToSelect = this.items.find(i => sm['@id'] === i['@id']);
      if (itemToSelect) {
        itemToSelect.selected = true;
      }
    })
  }

  public reset(): void {
    this.selectedMedia = [];
    this.items.forEach(i => i.selected = false);
    this.items = [...this.items];
    this.ig.updateItems();
  }

  public selectAll(): void {
    this.items.forEach(e => e.selected = true);
    this.selectedMedia = this.items;
    this.items = [...this.items];
    this.ig.updateItems();

  }
}
