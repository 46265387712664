import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {MakeApiModel} from "../models/make.api.model";

@Injectable()
export class MakeRestService {
  private readonly resource: string = '/makes';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getMakeCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<MakeApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<MakeApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<MakeApiModel>(MakeApiModel), result as object)));
  }
}
