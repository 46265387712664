<div class="app-grid-fluid md:mt-3 md:mb-3 py-2">
  <div class="grid grid-nogutter justify-content-center">
    <div class="col-12 sm:col-10 xl:col-5" [style]="{maxWidth: '900px'}">
      <p-dataView *ngIf="projects.length" [value]="projects" [paginator]="false" [rows]="100">
<!--        <ng-template pTemplate="header">-->
<!--          <p-card>-->
<!--            <div class="flex justify-content-between">-->
<!--              <h3>Top 100</h3>-->
<!--              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>-->
<!--            </div>-->
<!--          </p-card>-->
<!--        </ng-template>-->
        <ng-template let-projects pTemplate="list">
          <div *ngFor="let project of projects; index as i" class="col-12 p-0 m-0 mb-2">
            <p-card *ngIf="project.isPlaceholder">
              <div class="grid">
                <div class="col-1 justify-content-center align-items-center ranking-number hidden md:flex">
                  {{i + 1}}.
                </div>
                <div class="col">
                  <div class="col-12 p-0 pt-3 pb-2 vehicle-name white-space-nowrap overflow-hidden text-overflow-ellipsis" style="border: none">
                    <p-skeleton width="18rem" height="1.2rem"></p-skeleton>
                  </div>
                  <div class="col-12">
                    <div class="grid">
                      <div class="col-fixed p-0 theme-box">
                        <p-skeleton styleClass="avatar p-avatar-xl"></p-skeleton>
                      </div>
                      <div class="col pt-0">
                        <div class="grid grid-nogutter">
                          <div class="col-12 white-space-nowrap overflow-hidden text-overflow-ellipsis mb-2">
                            <p-skeleton width="10rem"></p-skeleton>
                          </div>
                          <div class="col-12 p-0 flex mb-2 gap-2" style="font-size: 1.1em">
                              <p-skeleton width="10rem" height="1.1rem"></p-skeleton>
                          </div>
                          <div class="col-12 p-0 flex mb-2 gap-2" style="font-size: 1.1em">
                            <p-skeleton width="10rem" height="1.1rem"></p-skeleton>
                          </div>
                          <div class="col-12 p-0 flex mb-2 gap-2" style="font-size: 1.1em">
                            <p-skeleton width="10rem" height="1.1rem"></p-skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-fixed flex flex-column justify-content-center">
                  <p-skeleton width="50px" height="50px" class="pb-2" [shape]="'circle'"></p-skeleton>
                  <p-skeleton width="50px" height="50px" class="pb-2" [shape]="'circle'"></p-skeleton>
                  <p-skeleton width="50px" height="50px" class="pb-2" [shape]="'circle'"></p-skeleton>
                </div>
              </div>
            </p-card>
            <p-card *ngIf="!project.isPlaceholder">
              <div class="grid">
                <div class="col-1 justify-content-center align-items-center ranking-number hidden md:flex">
                  {{i + 1}}.
                </div>
                <div class="col">
                  <div class="col-12 p-0 pt-3 pb-2 vehicle-name white-space-nowrap overflow-hidden text-overflow-ellipsis" style="border: none">
                    <a class="no-underline">{{project.vehicle.fullVehicleIdentity}}</a>
                  </div>
                  <div class="col-12">
                    <div class="grid">
                      <div class="col-5 p-0 theme-box">
                        <p-avatar size="xlarge" styleClass="avatar" image="{{project.theme | mediaObject: {defaultImageType: 'car'} }}">
                          <div class="justify-content-center align-items-center ranking-number md:hidden flex">
                            <span>{{i + 1}}.</span>
                          </div>
                        </p-avatar>
                      </div>
                      <div class="col pt-0">
                        <div class="grid grid-nogutter">
                          <div class="col-12 white-space-nowrap overflow-hidden text-overflow-ellipsis mb-2">
                            <i class="pi pi-user mr-2"></i>
                            <a [routerLink]="['/', project.user.slug, 'profile']">{{project.user.username}}</a>
                          </div>
                          <div class="col-12 p-0 flex mb-2 gap-2" style="font-size: 1.1em">
                            <div><div class="rating inline-flex gap-1">
                              <ng-container *ngFor="let star of [1, 2, 3, 4, 5, 6]">
                                <i [ngClass]="getIconForStar(star, project.averageRating.toPrecision(3))"></i>
                              </ng-container>
                            </div>
                            </div>

                          </div>
                          <div class="col-12 p-0 mb-2" style="font-size: 1.1em;">{{'common.ratings_average_short'|translate}} <span class="count">{{project.averageRating.toPrecision(3)}}</span> {{'common.from'|translate}}  <span class="count">{{project.ratingCount}}</span> {{'common.ratings'|translate | translateSelector: project.ratingCount}}</div>
                          <div class="col-12 p-0 mb-2 inline-flex align-items-center" style="font-size: 1.1em">{{'common.your_rate'|translate}}: <span *ngIf="project.currentUserRating > 0" class="ranking-rate px-1">{{ project.currentUserRating }}</span><ng-container *ngIf="project.currentUserRating === 0">~</ng-container></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-fixed pl-0 flex flex-column justify-content-center">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-warehouse"
                    class="p-button-outlined p-button-rounded p-button-primary p-button-sm m-1"
                    [routerLink]="['/', project.user.slug, 'garage']"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-car"
                    class="p-button-outlined p-button-rounded p-button-primary p-button-sm m-1"
                    [routerLink]="['/', project.user.slug, 'garage', project.slug]"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-user"
                    class="p-button-outlined p-button-rounded p-button-primary p-button-sm m-1"
                    [routerLink]="['/', project.user.slug, 'profile']"
                  ></button>
                </div>
              </div>
            </p-card>
          </div>
        </ng-template>
<!--        <ng-template let-project let-index="rowIndex"  pTemplate="gridItem">-->
<!--          <div class="col-12 p-md-3">-->
<!--            {{index + 1}} - {{project.vehicle.fullVehicleIdentity}}-->
<!--          </div>-->
<!--        </ng-template>-->
      </p-dataView>
    </div>
  </div>
</div>
